<template>
    <div class="divide-x flex flex-grow border overflow-y-scroll">
        <Mango
            collection="threads"
            :query="{search: {members: {$in: [store.user.id]}}}"
            @update:data="threads = $event"
            class="w-full xs:w-auto sm:w-full xs:max-w-xs hidden xs:block"
            :class="!selectedThread ? '!block' : 'hidden'"
        >
            <div class="flex flex-col divide-y">

                <button @click="createThreadPrompt = true; selectedThread = null" class="py-4"><i class="fal fa-edit opacity-50" /></button>

                <div v-if="createThreadPrompt" class="flex items-center space-x-4 py-4 px-6 bg-gray-100">
                    <div class="w-12 h-12 shrink-0 bg-gray-300 rounded-full" />
                    <div class="hidden md:flex flex-col w-full items-start space-y-2">
                        <div class="w-1/2 h-4 rounded-full bg-gray-800/10" />
                        <div class="w-3/4 h-2 rounded-full bg-gray-800/10" />
                        <!-- <div class="w-2/3 h-2 rounded-full bg-gray-800/10" /> -->
                    </div>
                </div>

                <Thread
                    v-for="thread in threads"
                    :key="thread.id"
                    :thread="thread"
                    @click="selectedThread = thread; createThreadPrompt = false"
                    :class="selectedThread?.id == thread.id ? 'bg-gray-100' : ''"
                />

            </div>
        </Mango>

        <div class="flex-grow  flex-col h-full" :class="selectedThread ? 'flex' : 'hidden xs:flex'">
            <div v-if="!selectedThread?.id" class="flex flex-grow flex-col justify-start bg-gray-100">
                <div v-if="createThreadPrompt" class="bg-white pt-2">
                    <MultiSelect collection="members" v-model:selectedEntries="selectedUsers" />
                </div>
            </div>
            <div v-else class="xs:hidden flex justify-between items-center p-4 border-b">
                <button class="w-4" @click="selectedThread = null"><i class="fa fa-chevron-left text-blue-500 text-xl" /></button>
                <div>
                    <div class="w-14 h-14 shrink-0 bg-gray-300 rounded-full bg-center bg-cover flex items-center justify-center" :style="group ? '' : `background-image: url(${images[0]})`">
                        <div v-if="!group && !images?.length" class="uppercase font-bold text-white text-lg">{{ others?.[0]?.firstName[0] }}{{ others?.[0]?.lastName[0] }}</div>
                    </div>
                    <div class="text-2xs mt-1 text-center">{{ title }}</div>
                </div>
                <div class="w-4"></div>
            </div>
            <div ref="messages" class="w-full flex-grow h-full p-4 bg-gray-50 overflow-scroll">
                <Mango v-if="selectedThread?.id" collection="messages" :query="{search: {thread: selectedThread.id}, limit: 100, fields: ['content','author','attachments'], sort: {created: -1}}" @update:data="messages = $event">
                    <div class="flex flex-col-reverse">
                        <Message v-for="message in messages" :key="message.id" :message="message" :thread="selectedThread" />
                    </div>
                </Mango>
            </div>
            <div class="p-2 bg-gray-100 dark:bg-gray-900/50 border-t dark:border-gray-800 flex flex-col">

                <div v-if="this.attachments?.length" class="mb-2">
                    <div v-for="a, i in attachments" :key="a" class="relative group w-32 rounded-lg overflow-hidden">
                        <button @click="attachments.splice(i, 1)" class="absolute right-1 top-1 rounded-full w-2 h-2 bg-red-500 border border-red-700 group-hover:block hidden"></button>
                        <img :src="a" class="w-full" />
                    </div>
                </div>

                <div class="w-full bg-white dark:bg-gray-900/50 p-1 border dark:border-gray-700 rounded-3xl flex justify-between">

                    <div class="relative w-full">
                        <div
                            contenteditable="true"
                            @keydown.enter.prevent="submitMessage"
                            @paste="handlePaste"
                            @drop.prevent="handleDrop"
                            @dragover.prevent
                            @input="handleInput"
                            @focus="inputFocused = true"
                            @blur="inputFocused = false"
                            ref="editableDiv"
                            class="relative bg-transparent flex-grow px-3 py-1 outline-none text-gray-800 dark:text-white/70 dark:placeholder:text-gray-700 max-h-[10vh] overflow-y-scroll"
                        >
                        </div>
                        <div v-if="!message" class="opacity-40 absolute pointer-events-none left-3 inset-y-0 mt-[3px] w-full block">Type your comment here</div>
                    </div>

                    <button
                        :class="message ? 'opacity-100' : 'opacity-0 pointer-events-none'"
                        class="transition-all aspect-square h-8 rounded-full bg-blue-500 dark:bg-glue-500/70 text-white shrink-0"
                        @click="submitMessage"
                    >
                        <i class="fas fa-arrow-up" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Mango from '../../../../front/src/helpers/mango'
import MultiSelect from '../../../../front/src/helpers/multiSelect.vue'
import Thread from './thread.vue'
import { io } from 'socket.io-client';
import Message from './message.vue';
import axios from 'axios';

export default {
    components: { MultiSelect, Thread, Message },
    inject: ['store'],
    data() {
        return {
            socket: null,
            threads: [],
            messages: [],
            attachments: [],
            selectedUsers: [],
            createThreadPrompt: false,
            message: null,
            selectedThread: null,
            inputFocused: false,
        }
    },
    mounted() {

        // Connect to the /chat namespace
        this.socket = io(`${this.store.api}/messages`, { transports: ['websocket'] });
        this.socket.emit('subscribeToThread', this.store.user?.id);

        // Listen for the 'message' event
        this.socket.on('message', (msg) => {
            console.log('Received message:', msg);
            let thread = this.threads?.find(thread => thread.id == msg.thread?.id)
            thread.preview = msg.content
            if (msg.thread?.id == this.selectedThread?.id) {
                this.messages.unshift(msg)
                this.scrollMessages()
                axios.post(`${this.store.api}/controllers/markRead`, {thread: this.selectedThread?.id})
            } else {
                thread.readBy = []
            }
        });

    },
    watch: {
        messages() {
            console.log('this?.$refs?.messages', this?.$refs?.messages)
            this.scrollMessages()
        },
        selectedThreadId: {
            immediate: true,
            handler(value, old) {
                let selectedThread = this.threads?.find(thread => thread.id == value)
                selectedThread?.readBy?.push(this.store.user)
                // if (old?.id) this.socket.emit('unsubscribeFromThread', old?.id);
                // if (value?.id) {
                //     this.messages = []
                //     this.socket.emit('subscribeToThread', value?.id);
                // }
            }
        }
    },
    methods: {
        scrollMessages() {
            if (this?.$refs?.messages) this.$nextTick(() => this.$refs.messages.scrollTop = this?.$refs?.messages?.scrollHeight)
        },
        async createThread() {
            if (!this.selectedUsers?.length) return
            return await Mango.threads.save({
                members: [this.store.user, ...this.selectedUsers]
            })
        },
        async submitMessage() {
            if (!this.message && !this.attachments?.length) return
            if (!this.selectedThread?.id) {
                this.selectedThread = await this.createThread()
                this.threads.push(this.selectedThread)
                this.createThreadPrompt = false
            }
            let content = this.message
            let data = {
                content,
                thread: this.selectedThread,
            }
            if (this.attachments?.length) data.attachments = this.attachments
            // this.messages.push({content, author: this.store.user})
            this.message = null
            if (this?.$refs?.editableDiv?.innerHTML) this.$refs.editableDiv.innerHTML = ''
            this.attachments = []
            let message = await Mango.messages.save(data)

            if (!message?.id) return
        },
        handleInput() {
            this.message = this?.$refs?.editableDiv?.innerText?.trim();
        },
        handlePaste(event) {
            const clipboardItems = event.clipboardData.items;
            for (let item of clipboardItems) {
                if (item.kind === 'file') {
                    event.preventDefault();
                    const file = item.getAsFile();
                    this.handleFileUpload(file);
                }
            }
        },
        handleDrop(event) {
            const files = event.dataTransfer.files;
            if (files.length) {
                for (let file of files) {
                    this.handleFileUpload(file);
                }
            }
        },
        async handleFileUpload(file) {
            let url = await Mango.upload(file)
            this.attachments.push(url)
        }
    },
    computed: {
        selectedThreadId() {
            return this.selectedThread?.id
        },
        group() {
            return this.selectedThread?.members?.length > 2
        },
        others() {
            return this.selectedThread?.members?.filter(m => m.id != this.store.user?.id)
        },
        title() {
            return this.others.map(m => m?.firstName).join(', ')
        },
        images() {
            return this.others?.filter(m => !!m?.image?.url)?.map(m => m?.image?.url)
        },
    }
}
</script>

<style>

</style>
