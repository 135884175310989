<template>

    <div class="w-full flex-grow py-12 px-8 flex flex-col">

		<div class="flex flex-col justify-start items-start space-y-5">

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Owner First Name</h3>
				<input v-model="formData.firstName" type="text" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Owner Last Name</h3>
				<input v-model="formData.lastName" type="text" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Revenue</h3>
				<input v-model.number="revenue" type="number" inputmode="decimal" class="px-3 py-2 border w-full">
			</div>

		</div>

		<button @click="next" :class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`" class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-end mt-8">
            Submit
        </button>

    </div>

</template>

<script>
import Swal from 'sweetalert2'
import Mango from '../../helpers/mango.js'
import Address from '../layout/address.vue'

export default {
    components: { Address },
    props: ['opportunity'],
    inject: ['store'],
    data() {
        return {
            savingOpportunity: false,
			address: {
                formatted: null
            },
			formData: {
                firstName: null,
                lastName: null,
			},
            revenue: null
        }
    },
    watch: {
        savingOpportunity() {
            this.$emit('saving', this.savingOpportunity)
        }
    },
    methods: {
        parseRevenue(revenueString) {

            if (!isNaN(revenueString)) return Number(revenueString)

            // Remove non-numeric characters from the string
            const numericString = revenueString.replace(/[^0-9]/g, '');

            // Parse the numeric string to an integer
            const revenue = parseInt(numericString, 10);

            // Check if it's a valid integer, if not, return 0 or handle the error as needed
            if (!isNaN(revenue)) {
                return Number(revenue);
            } else {
                return 0; // You can choose to return a default value or handle the error differently
            }
        },
        async next() {
            if (isNaN(this.revenue)) return Swal.fire({icon: 'warning', title: 'Invalid Revenue', text: 'Please enter a valid number for the revenue.'})
            if (!this.formData?.lastName) return Swal.fire({icon: 'warning', text: `Please enter the owner's last name for this address.`})
            this.savingOpportunity = true
            let timestamps = this.opportunity.timestamps || {}
            timestamps.revenue = this.revenue ? new Date() : null
            await this.store.localDB.save({ id: this.opportunity.id, ownerInfo: this.formData, revenue: this.revenue, timestamps })
            this.savingOpportunity = false
            if (this.opportunity?.retail) return this.$router.push(`/opportunities/${this.opportunity.id}/lead-result`)
            this.$router.push(`/account/my-opportunities`)
        }
    },
	created() {
		const savedInfo = this.opportunity?.ownerInfo || {}
        this.revenue = this.opportunity?.revenue || null
        this.formData = {...this.formData, ...savedInfo}
	}
}
</script>
