<template>
    <div v-if="opportunity">

        <Modal v-if="open" @hide="open = null" maxWidth="max-w-2xl">
            <Spinner v-if="!opportunity?.ktwc" :small="true" class="w-8 h-8 mx-auto" />
            <div v-else class="relative" :class="{'pointer-events-none opacity-50': opportunity.status == 'Lockout'}">
                <div class="border-b pb-4 mb-8 flex justify-between">
                    <div class="text-xl font-semibold">{{ opportunity.title }}</div>
                    <div class="ml-2">
                        <div
                            class="text-2xs px-1 py-[2px] bg-gray-100 rounded whitespace-nowrap"
                            :class="{
                                'border border-red-400/50 text-red-400 bg-red-50': ['closed','Lockout'].includes(opportunity.status),
                                'border border-green-500/50 text-green-500 bg-green-50': ['open','featured','Insurance Accepted','Contract Signed','Contingency'].includes(opportunity.status),
                                'border border-yellow-400/50 text-yellow-500 bg-yellow-50': ['pending','Soft Set'].includes(opportunity.status),
                                'border border-orange-400/50 text-orange-400 bg-orange-50': opportunity.status == 'hidden',
                                'border border-orange-400/50 text-orange-400 bg-orange-50': opportunity.status == 'Retail',
                                'border border-black/20 text-black bg-black/10': ['Skip','No Answer','Not Interested'].includes(opportunity.status),
                            }"
                        >
                            {{ opportunity.status || 'Skip' }}
                        </div>
                    </div>
                </div>
                <div class="flex md:space-x-4 w-full justify-between flex-col-reverse md:flex-row">
                    <div class="space-y-4 flex-grow text-sm mt-4 md:mt-0">

                        <!-- <div class="flex space-x-2 items-center">
                            <div v-if="opportunity.jnid">
                                <a
                                    :href="`https://app.jobnimbus.com/contact/${opportunity.jnid}`"
                                    target="_blank" class="bg-[#314f8f] rounded text-white px-3 py-2"
                                >
                                    View in JobNimbus
                                    <img src="https://webappui.jobnimbus.com/images/favicon.png?" class="w-6 block" />
                                </a>
                            </div>

                            <div v-if="!store.production" class="">
                                <a :href="`/opportunities/${opportunity.id}/estimate`" target="_blank" class="bg-red-500 rounded text-white px-3 py-2">Create Estimate</a>
                            </div>
                        </div> -->

                        <div class="flex flex-wrap justify-between">
                            <div class="">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Knock</div>
                                <Toggle @change="saveOpportunity(opportunity)" v-model="opportunity.ktwc.knock" :small="false" background="bg-gray-100 dark:bg-gray-700" />
                            </div>
                            <div class="">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Talk</div>
                                <Toggle @change="saveOpportunity(opportunity)" v-model="opportunity.ktwc.talk" :small="false" background="bg-gray-100 dark:bg-gray-700" />
                            </div>
                            <div class="">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Walk</div>
                                <Toggle @change="saveOpportunity(opportunity)" v-model="opportunity.ktwc.walk" :small="false" background="bg-gray-100 dark:bg-gray-700" />
                            </div>
                            <div class="">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Ctgy</div>
                                <Toggle @change="saveOpportunity(opportunity)" v-model="opportunity.ktwc.contingency" :small="false" background="bg-gray-100 dark:bg-gray-700" />
                            </div>
                        </div>

                        <div class="bg-gray-100 p-4 rounded-lg opacity-80">
                            <!-- <div class="text-xs uppercase tracking-widest font-semibold opacity-50">Installation</div> -->
                            <!-- <div class="uppercase text-xs font-semibold opacity-30 tracking-widest mb-2 text-center">Installation Status</div> -->
                            <div class="flex flex-wrap justify-between">
                                <div class="opacity-50">
                                    <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-2">Installation Started</div>
                                    <!-- @update:modelValue="opportunity.installationStarted.value = $event ? new Date() : null; saveOpportunity(opportunity)" -->
                                    <Toggle
                                        @change="saveOpportunity(opportunity)"
                                        :disabled="true"
                                        :modelValue="!!opportunity.installationStarted?.value"
                                        :small="false"
                                        background="bg-gray-300 dark:bg-gray-700"
                                    />
                                </div>
                                <!-- <div class="opacity-50">
                                    <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-2">Completed</div>
                                    <Toggle
                                        @change="saveOpportunity(opportunity)"
                                        :disabled="true"
                                        :modelValue="!!opportunity.installationCompleted?.value"
                                        :small="false"
                                        background="bg-gray-100 dark:bg-gray-700"
                                    />
                                </div> -->
                            </div>
                        </div>

                        <div class="" v-if="opportunity?.ownerInfo?.firstName">
                            <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Soft Set</div>
                            <div class="flex space-x-4">
                                <input @change="saveOpportunity(opportunity)" v-model="opportunity.ownerInfo.bestDate" type="date" class="px-3 py-2 border w-full rounded">
                                <input @change="saveOpportunity(opportunity)" v-model="opportunity.ownerInfo.bestTime" type="time" class="px-3 py-2 border w-full rounded">
                            </div>
                        </div>

                        <div class="border-2 p-4 text-xs font-mono shadow-lg" v-if="Object.keys(opportunity.timestamps).some(v => opportunity.timestamps[v])">
                            <div class="uppercase text-2xs font-semibold opacity-30 tracking-widest mb-2">Timestamps</div>
                            <div v-if="opportunity.timestamps.knock">{{ inRange(opportunity, 'knock') ? '✅' : '❌' }} <strong>Knocked:</strong> {{ dayjs(opportunity?.timestamps?.knock).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="opportunity.timestamps.talk">{{ inRange(opportunity, 'talk') ? '✅' : '❌' }} <strong>Talked:</strong> {{ dayjs(opportunity?.timestamps?.talk).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="opportunity.timestamps.walk">{{ inRange(opportunity, 'walk') ? '✅' : '❌' }} <strong>Walked:</strong> {{ dayjs(opportunity?.timestamps?.walk).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="opportunity.timestamps.contingency">{{ inRange(opportunity, 'contingency') ? '✅' : '❌' }} <strong>Contingency Logged:</strong> {{ dayjs(opportunity?.timestamps?.contingency).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="opportunity.timestamps.softSet">{{ inRange(opportunity, 'softSet') ? '✅' : '❌' }} <strong>Soft Set Logged:</strong> {{ dayjs(opportunity?.timestamps?.softSet).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="opportunity.contractSigned">{{ inRange(opportunity, 'contractSigned') ? '✅' : '❌' }} <strong>Contract Signed:</strong> {{ dayjs(opportunity?.contractSigned).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="opportunity.insuranceAccepted">{{ inRange(opportunity, 'insuranceAccepted') ? '✅' : '❌' }} <strong>Insurance Accepted:</strong> {{ dayjs(opportunity?.insuranceAccepted).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="opportunity.timestamps.revenue">{{ inRange(opportunity, 'revenue') ? '✅' : '❌' }} <strong>Revenue Changed:</strong> {{ dayjs(opportunity?.timestamps?.revenue).format('MM/DD/YY h:mma') }}</div>
                        </div>

                        <router-link :to="`/opportunities/${opportunity.id}/owner-info?next=/account/my-opportunities`" class="bg-gray-100 rounded-lg p-4 text-sm block">
                            <div class="uppercase text-2xs font-semibold opacity-30 tracking-widest mb-2">Owner Info</div>
                            <div>
                                <strong>Name:</strong>
                                {{ opportunity?.ownerInfo?.firstName }} {{ opportunity?.ownerInfo?.lastName }}
                                <span v-if="opportunity?.ownerInfo?.goesBy">"{{ opportunity?.ownerInfo?.goesBy }}"</span>
                            </div>
                            <div><strong>Phone:</strong> {{ opportunity?.ownerInfo?.phoneNumber }}</div>
                            <div><strong>Text:</strong> {{ opportunity?.ownerInfo?.callOrText }}</div>
                            <div><strong>Email:</strong> {{ opportunity?.ownerInfo?.emailAddress }}</div>
                            <div><strong>Best Time:</strong> {{ opportunity?.ownerInfo?.bestTime }}</div>
                            <div><strong>Best Date:</strong> {{ opportunity?.ownerInfo?.bestDate }}</div>
                            <div><strong>Notes:</strong> {{ opportunity?.ownerInfo?.notes }}</div>
                        </router-link>

                        <div class="bg-gray-100 rounded-lg p-4 text-sm" v-if="opportunity?.insuranceInfo?.insuranceCarrier">
                            <div class="uppercase text-2xs font-semibold opacity-30 tracking-widest mb-2">Insurance Info</div>
                            <div><strong>Insurance Carrier:</strong> {{ opportunity?.insuranceInfo?.insuranceCarrier }}</div>
                            <div><strong>Policy Number:</strong> {{ opportunity?.insuranceInfo?.policyNumber }}</div>
                            <div><strong>Claim Number:</strong> {{ opportunity?.insuranceInfo?.claimNumber }}</div>
                            <div><strong>Adjuster Name:</strong> {{ opportunity?.insuranceInfo?.adjusterName }}</div>
                            <div><strong>Deductible:</strong> {{ opportunity?.insuranceInfo?.deductible }}</div>
                            <div><strong>Notes:</strong> {{ opportunity?.insuranceInfo?.notes }}</div>
                        </div>

                        <div>
                            <div><strong>Damage Locations:</strong> {{ opportunity.damageObserved?.locations?.join(', ') }}</div>
                            <div><strong>Damaged Items:</strong> {{ opportunity.damageObserved?.items?.join(', ') }}</div>
                        </div>

                        <div>
                            <div><strong>Objections:</strong> {{ opportunity.objections?.objections?.join(', ') }}</div>
                            <div><strong>Objection Notes:</strong> {{ opportunity.objections?.notes }}</div>
                        </div>

                        <div>
                            <div><strong>Damage Type:</strong> {{ opportunity.damageType?.join(', ') }}</div>
                            <div><strong>Discussion:</strong> {{ opportunity.discussion }}</div>
                            <div><strong>Inspection:</strong> {{ opportunity.inspection }}</div>
                            <div><strong>Insurance Accepted:</strong> {{ opportunity?.insuranceAccepted ? 'Yes' : 'No' }}</div>
                            <div><strong>Contract Signed:</strong> {{ opportunity?.contractSigned ? 'Yes' : 'No' }}</div>
                            <div><strong>Revenue:</strong> {{ (opportunity.revenue || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) }}</div>
                            <div><strong>Estimate:</strong> {{ (opportunity.estimate || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) }}</div>
                        </div>

                    </div>
                    <div class="w-full md:max-w-48 md:ml-4 md:mb-4 md:float-right">
                        <img @click.stop="selectedPhoto = opportunity.selfie?.url" v-if="opportunity?.selfie?.url" :src="opportunity.selfie.url" class="w-full rounded" />
                    </div>
                </div>
            </div>
        </Modal>

        <Modal v-if="selectedPhoto" @hide="selectedPhoto = null" maxWidth="max-w-4xl !p-0">
            <img :src="selectedPhoto" class="w-full" />
        </Modal>

        <Modal v-if="promptForOwnerInfo" @hide="promptForOwnerInfo = false; opportunity = JSON.parse(JSON.stringify(originalEntry))" maxWidth="max-w-4xl" v-slot="hide">
            <div>Owner Name Required</div>
            <div class="flex space-x-4">
                <input type="text" v-model="opportunity.ownerInfo.firstName" placeholder="First Name">
                <input type="text" v-model="opportunity.ownerInfo.lastName" placeholder="Last Name">
            </div>
            <button @click="callback" class="px-3 py-2 rounded bg-red-500 text-white">Continue</button>
        </Modal>

        <Modal v-if="promptForRevenue" @hide="promptForRevenue = false; opportunity = JSON.parse(JSON.stringify(originalEntry))" maxWidth="max-w-4xl" v-slot="hide">
            <div>Revenue Required when Contract Signed</div>
            <div class="flex space-x-1 items-center">
                <span>$</span><input type="number" inputmode="decimal" v-model.number="opportunity.revenue" placeholder="Revenue">
            </div>
            <button @click="callback" class="px-3 py-2 rounded bg-red-500 text-white">Continue</button>
        </Modal>

        <div
            class="lg:flex items-center lg:space-x-8 md:hover:bg-gray-50 w-full"
            :class="{'bg-gray-50': opportunity.status == 'Lockout'}"
            v-touch:swipe.left="() => proposeDelete = true"
            v-touch:swipe.right="() => proposeDelete = null"
            v-touch:tap="() => proposeDelete = null"
            @touchstart="startTouch"
            @touchmove="moveTouch"
            @touchend="endTouch"
        >
            <div class="w-full flex">

                <div class="w-full shrink-0 p-4 transition-all relative" :class="{'-ml-36': proposeDelete}">
                    <div class="flex items-center space-x-4 md:w-full">
                        <div
                            @click.stop="selectedPhoto = opportunity.selfie?.url"
                            class="w-20 h-20 shrink-0 rounded aspect-video bg-center bg-cover bg-gray-600 text-white flex items-center justify-center border-2"
                            v-lazy:background-image="opportunity.selfie?.url"
                        >
                            <div v-if="!opportunity.selfie?.url" class="text-2xl font-bold">{{ opportunity.ownerInfo?.firstName?.[0] }}{{ opportunity.ownerInfo?.lastName?.[0] }}</div>
                        </div>
                        <div class="w-full flex-grow" @click="openOp">
                            <div class="text-2xs text-red-300 font-semibold whitespace-nowrap text-gray-500">{{ dayjs(opportunity.created).format('MMM D, h:mma') }}</div>
                            <div class="text-xs text-gray-500">
                                {{ opportunity.ownerInfo?.firstName }} {{ opportunity.ownerInfo?.lastName }}
                                <span v-if="opportunity.ownerInfo?.goesBy">"{{ opportunity.ownerInfo?.goesBy }}"</span>
                            </div>
                            <div class="text-sm font-semibold mt-2">{{ opportunity.address?.address }}</div>
                            <div class="text-2xs text-gray-500">{{ opportunity.address?.city }}, {{ opportunity.address?.state }} {{ opportunity.address?.zip }}</div>
                        </div>
                        <div class="flex flex-col items-end">
                            <div
                                class="text-2xs px-1 py-[2px] bg-gray-100 rounded absolute mb-2 top-3 right-3"
                                :class="{
                                    'border border-green-500/50 text-green-500 bg-green-50': ['open','featured','Insurance Accepted','Contract Signed','Contingency'].includes(opportunity.status),
                                    'border border-yellow-400/50 text-yellow-500 bg-yellow-50': ['pending','Soft Set'].includes(opportunity.status),
                                    'border border-orange-400/50 text-orange-400 bg-orange-50': opportunity.status == 'hidden',
                                    'border border-orange-400/50 text-orange-400 bg-orange-50': opportunity.status == 'Retail',
                                    'border border-red-400/50 text-red-400 bg-red-50': ['closed','Lockout'].includes(opportunity.status),
                                    'border border-black/20 text-black bg-black/10': ['Skip','No Answer','Not Interested'].includes(opportunity.status),
                                }"
                            >
                                {{ opportunity.status || 'Skip' }}
                            </div>
                            <template v-if="opportunity.status != 'Lockout'">
                                <router-link v-if="opportunity.status == 'Retail'" :to="`/opportunities/${opportunity.id}/retail`" class="text-blue-500 flex items-center text-sm underline">Edit</router-link>
                                <router-link v-else :to="`/opportunities/${opportunity.id}/observations`" class="text-blue-500 flex items-center text-sm underline">Edit</router-link>
                            </template>
                        </div>
                    </div>
                    <div class="flex space-x-4 items-center mt-2">
                        <label class="flex space-x-2 items-center justify-start pt-2">
                            <div class="text-2xs">Insurance Accepted</div>
                            <div class="w-full"><Toggle @click.stop :small="true" :modelValue="opportunity.insuranceAccepted" @update:modelValue="opportunity.insuranceAccepted = $event ? new Date() : null; saveOpportunity(opportunity, 'insurance')" /></div>
                        </label>
                        <label class="flex space-x-2 items-center justify-start pt-2">
                            <div class="text-2xs">Contract Signed</div>
                            <div class="w-full"><Toggle @click.stop :small="true" :modelValue="opportunity.contractSigned" @update:modelValue="opportunity.contractSigned = $event ? new Date() : null; saveOpportunity(opportunity, 'contract')" /></div>
                        </label>
                        <div class="flex flex-col items-center pt-2">
                            <input type="number" inputmode="decimal" v-model.number="opportunity.revenue" class="text-2xs px-2 py-1 border w-16" placeholder="revenue" @change="saveOpportunity(opportunity, 'revenue')" />
                        </div>
                        <div class="flex flex-col items-center pt-2">
                            <input type="number" inputmode="decimal" v-model.number="opportunity.estimate" class="text-2xs px-2 py-1 border w-16" placeholder="estimate" @change="saveOpportunity(opportunity, 'estimate')" />
                        </div>
                    </div>
                </div>

                    <!-- v-if="proposedDeleteId == opportunity.id"  -->
                <div
                    @click.stop="deleteOpportunity(opportunity)"
                    class="bg-red-500 text-white px-8 flex items-center justify-center w-36 ml-px font-semibold"
                >
                    Delete
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import Toggle from '../partials/toggle.vue'
import Mango from '../../helpers/mango'
import latestCutoff from '../../helpers/latestCutoff'
import Swal from 'sweetalert2'

export default {
	components: { Toggle },
    inject: ['store','latestCutoff'],
    props: ['op'],
    data() {
        return {
            open: false,
            selectedPhoto: null,
            opportunity: this.op,
            proposeDelete: false,
            savingOpportunity: false,
            promptForOwnerInfo: false,
            promptForRevenue: false,
            latestCutoff: latestCutoff(),
            originalEntry: JSON.parse(JSON.stringify(this.op)),
            callback: null,
        }
    },
    methods: {
        dayjs,
        async openOp() {
            if (this.proposedDeleteId) return
            this.open = true
            let o = await Mango.opportunity(this.opportunity.id)
            if (!o.ktwc) o.ktwc = {}
            if (!o.timestamps) o.timestamps = {}
            this.originalEntry = JSON.parse(JSON.stringify(o)),
            this.opportunity = o
        },
        inRange(l, param) {
            param = l?.timestamps?.[param] || l?.[param]
            return new Date(param) > this.latestCutoff
        },
        async saveOpportunity(opportunity, changed) {

            this.promptForOwnerInfo = null
            this.promptForRevenue = null
            if ((opportunity.contractSigned) && (!opportunity.revenue || opportunity.revenue < 1)) {
                this.promptForRevenue = true
                return this.callback = () => this.saveOpportunity(opportunity, changed)
            }
            if ((opportunity.contractSigned || opportunity.revenue || opportunity.ktwc?.contingency) && !opportunity.ownerInfo?.lastName) {
                this.promptForOwnerInfo = true
                return this.callback = () => this.saveOpportunity(opportunity, changed)
            }

            let { id, insuranceAccepted, contractSigned, revenue, estimate} = opportunity
            if (isNaN(revenue)) return Swal.fire({icon: 'warning', title: 'Invalid Revenue', text: 'Please enter a valid number for the revenue.'})
            if (!isNaN(revenue) && Number(revenue) > 2500000) return Swal.fire({icon: 'warning', title: 'Revenue Limit Reached', text: 'Revenue cannot be greater than $2.5M'})
            this.savingOpportunity = true

            if (revenue) revenue = this.parseRevenue(revenue)
            if (estimate) estimate = this.parseRevenue(estimate)
            if (revenue != this.originalEntry?.revenue) opportunity.timestamps.revenue = new Date()
            if (estimate != this.originalEntry?.estimate) opportunity.timestamps.estimate = new Date()

            opportunity.status = this.computeStatus(opportunity)

            // Quick Saves - The rest of this function requires data unavailable until the opportunity is selected
            if (['insurance','contract','revenue','estimate'].includes(changed)) {

                if (changed == 'revenue') opportunity.timestamps.revenue = new Date()
                if (changed == 'estimate') opportunity.timestamps.estimate = new Date()

                await this.store.localDB.save({id, insuranceAccepted, contractSigned, revenue, estimate, timestamps: opportunity.timestamps, ownerInfo: this.opportunity.ownerInfo})
                return this.savingOpportunity = false

            }

            // if (changed == 'contract') opportunity.timestamps.contingency = contractSigned ? new Date() : null

            console.log('opportunity?.ktwc?.knock', opportunity?.ktwc?.knock, changed)

            // Orignal Entry is only set when the modal is open, it's not set when changing things in the list view
            if (this.originalEntry) {
                if (opportunity?.ktwc?.knock != this.originalEntry?.ktwc?.knock) {
                    opportunity.ktwc.knock = opportunity?.ktwc?.knock ? 1 : 0
                    opportunity.timestamps.knock = opportunity?.ktwc?.knock ? new Date() : null
                }
                if (opportunity?.ktwc?.talk != this.originalEntry?.ktwc?.talk) {
                    opportunity.ktwc.talk = opportunity?.ktwc?.talk ? 1 : 0
                    opportunity.timestamps.talk = opportunity?.ktwc?.talk ? new Date() : null
                }
                if (opportunity?.ktwc?.walk != this.originalEntry?.ktwc?.walk) {
                    opportunity.ktwc.walk = opportunity?.ktwc?.walk ? 1 : 0
                    opportunity.timestamps.walk = opportunity?.ktwc?.walk ? new Date() : null
                }
                if (opportunity?.ktwc?.contingency != this.originalEntry?.ktwc?.contingency) {
                    opportunity.ktwc.contingency = opportunity?.ktwc?.contingency ? 1 : 0
                    opportunity.timestamps.contingency = opportunity?.ktwc?.contingency ? new Date() : null
                }

                let timeAdded = opportunity.ownerInfo?.bestTime && !this.originalEntry?.ownerInfo?.bestTime
                let dateAdded = opportunity.ownerInfo?.bestDate && !this.originalEntry?.ownerInfo?.bestDate
                let alreadySoftSet = (this.originalEntry?.inspection?.toLowerCase?.() == 'soft set' || this.originalEntry?.discussion?.toLowerCase?.() == 'soft set')
                if ((timeAdded || dateAdded) && !alreadySoftSet) {
                    opportunity.timestamps.softSet = new Date()
                    opportunity.inspection = 'Soft Set'
                    opportunity.status = 'Soft Set'
                }
            }

            // if (entryCopy?.inspection?.toLowerCase?.() == 'soft set' && entryCopy?.inspection != this.originalEntry?.inspection) entryCopy.timestamps.softSet = new Date()
            // if (entryCopy?.discussion?.toLowerCase?.() == 'soft set' && entryCopy?.discussion != this.originalEntry?.discussion) entryCopy.timestamps.softSet = new Date()

            let data = {
                id,
                insuranceAccepted,
                contractSigned,
                revenue,
                estimate,
                inspection: opportunity.inspection,
                timestamps: opportunity.timestamps,
                ownerInfo: opportunity.ownerInfo,
                ktwc: opportunity.ktwc,
                // installationStarted: opportunity.installationStarted?.value,
                // installationCompleted: opportunity.installationCompleted?.value,
            }

            // if (opportunity.ownerInfo?.bestDate) data["ownerInfo.bestDate"] = opportunity.ownerInfo?.bestDate
            // if (opportunity.ownerInfo?.bestTime) data["ownerInfo.bestTime"] = opportunity.ownerInfo?.bestTime

            opportunity.status = this.computeStatus(opportunity)

            await this.store.localDB.save(data)

            this.originalEntry = JSON.parse(JSON.stringify(opportunity))
            this.savingOpportunity = false
        },
        async deleteOpportunity() {
            let approved = (await Swal.fire({
                title: 'Are you sure?',
                text: `You will not be able to recover this opportunity! ${this.opportunity.address?.formatted}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }))?.isConfirmed
            if (approved) {
                let response = await Mango.opportunities.delete(this.opportunity)
                if (response.deleted) {
                    this.$emit('deleted', this.opportunity.id)
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: response.response,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        },
        parseRevenue(revenueString) {

            if (!isNaN(revenueString)) return Number(revenueString)

            // Remove non-numeric characters from the string
            const numericString = revenueString.replace(/[^0-9]/g, '');

            // Parse the numeric string to an integer
            const revenue = parseInt(numericString, 10);

            // Check if it's a valid integer, if not, return 0 or handle the error as needed
            if (!isNaN(revenue)) {
                return Number(revenue);
            } else {
                return 0; // You can choose to return a default value or handle the error differently
            }
        },
        computeStatus(opportunity) {
            if (opportunity?.discussion?.toLowerCase?.() === 'lockout' && opportunity?.addressLockoutApproved) return 'Lockout'
            if (opportunity?.damageType?.includes('retail') || opportunity.retail) return 'Retail'
            if (opportunity?.contractSigned) return 'Contract Signed'
            if (opportunity?.insuranceAccepted) return 'Insurance Accepted'
            if (opportunity?.ktwc?.contingency) return 'Contingency'
            if (opportunity?.inspection?.toLowerCase?.() === 'contingency') return 'Contingency'
            if (opportunity?.inspection?.toLowerCase?.() === 'soft set') return 'Soft Set'
            if (opportunity?.discussion?.toLowerCase?.() === 'soft set') return 'Soft Set'
            if (opportunity?.discussion?.toLowerCase?.() === 'not interested') return 'Not Interested'
            if (opportunity?.discussion?.toLowerCase?.() === 'no answer') return 'No Answer'
            return 'Skip'
        },
    }
}
</script>

<style lang="postcss" scoped>
input,
select {
    @apply border border-gray-300 rounded outline-blue-400 dark:outline-blue-500/40 px-3 py-2 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-600 w-full
}
</style>
