<template>
    <div class="w-full flex flex-col items-center space-y-4 justify-center p-4 border-b">

        <div
            class="flex justify-center w-full h-14 bg-center bg-contain bg-no-repeat max-w-xs"
            :style="`background-image: url(/images/logo.jpg)`"
            data-test="logo"
        />

        <!-- <div v-if="homepage" class="flex space-x-4 font-mono">
            <div
                v-for="element in ['knock', 'talk', 'walk', 'contingency']"
                :key="element"
                :class="`bg-${store.theme?.color}-500`"
                class="text-white w-16 h-16 rounded-xl border-2 flex items-center justify-center text-2xl relative uppercase"
            >
                {{ element[0] }}
            </div>
        </div> -->

        <div v-if="homepage" class="flex space-x-4 font-mono">
            <!-- <div class="w-16 h-16 rounded-xl border-2 flex items-center justify-center text-2xl bg-white relative">{{ store.ktwc.knock || 0 }}</div>
            <div class="w-16 h-16 rounded-xl border-2 flex items-center justify-center text-2xl bg-white relative">{{ store.ktwc.talk || 0 }}</div>
            <div class="w-16 h-16 rounded-xl border-2 flex items-center justify-center text-2xl bg-white relative">{{ store.ktwc.walk || 0 }}</div>
            <div class="w-16 h-16 rounded-xl border-2 flex items-center justify-center text-2xl bg-white relative">{{ store.ktwc.contingency || 0 }}</div> -->

            <!-- <div>{{ averageConversion }} {{ store.weeklyConversion }}</div> -->

            <div :class="statusLight.knock" class=" w-16 h-16 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                <div class="text-2xs tracking-widest uppercase leading-tight opacity-50">Knock</div>
                <div class="">{{ ktwc.knock || 0 }}</div>
            </div>
            <div :class="statusLight.talk" class=" w-16 h-16 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                <div class="text-2xs tracking-widest uppercase leading-tight opacity-50">talk</div>
                <div class="">{{ ktwc.talk || 0 }}</div>
            </div>
            <div :class="statusLight.walk" class=" w-16 h-16 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                <div class="text-2xs tracking-widest uppercase leading-tight opacity-50">walk</div>
                <div class="">{{ ktwc.walk || 0 }}</div>
            </div>
            <div :class="statusLight.contingency" class=" w-16 h-16 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                <div class="text-2xs tracking-widest uppercase leading-tight opacity-50">ctgy</div>
                <div class="">{{ ktwc.contingency || 0 }}</div>
            </div>
        </div>

        <!-- <div v-if="homepage && !store.production && !isNaN(store?.weeklyAverage?.conversion)" class="text-xs max-w-xs text-center p-4 rounded-lg bg-gray-100 space-y-2">

            <div class="text-sm font-bold uppercase tracking-2xl opacity-30">Weekly Average</div>
            <div class="flex space-x-4 font-mono justify-center">
                <div :class="statusLight.average.knock" class="w-10 h-10 rounded-xl border-2 flex flex-col items-center justify-center relative">
                    <div class="text-lg">{{ averageKtwc?.knock?.toFixed?.(0) || 0 }}</div>
                </div>
                <div :class="statusLight.average.talk" class="w-10 h-10 rounded-xl border-2 flex flex-col items-center justify-center relative">
                    <div class="text-lg">{{ averageKtwc?.talk?.toFixed?.(0) || 0 }}</div>
                </div>
                <div :class="statusLight.average.walk" class="w-10 h-10 rounded-xl border-2 flex flex-col items-center justify-center relative">
                    <div class="text-lg">{{ averageKtwc?.walk?.toFixed?.(0) || 0 }}</div>
                </div>
                <div :class="statusLight.average.contingency" class="w-10 h-10 rounded-xl border-2 flex flex-col items-center justify-center relative">
                    <div class="text-lg">{{ averageKtwc?.contingency?.toFixed?.(0) || 0 }}</div>
                </div>
            </div>

            <div>
                Your weekly conversion rate of {{ store?.weeklyAverage?.conversion?.toFixed?.(0) }}% is {{ average ? 'above' : 'below' }} the average of {{ store?.averageConversion?.toFixed?.(0) }}%
                <span v-if="average">Keep up the great work!</span>
                <span v-else><a href="" class="text-blue-500 underline">Click here</a> for some training videos that may help increase your contingencies.</span>
            </div>
        </div> -->

    </div>
</template>

<script>
// Function for setting cookies
let setCookie = function (cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function timeout(ms) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error(`Timeout after ${ms} ms`));
        }, ms);
    });
}

async function runWithTimeout(callback, ms) {
    try {
        let response = await Promise.race([
            callback(),
            timeout(ms)
        ]);
        return response;
    } catch (error) {
        // Handle timeout or other errors
        console.error(error.message);
        // You might want to re-throw the error or handle it differently
    }
}

export default {
    inject: ['axios','store'],
    data() {
        return {
            copied: 0,
            benchmarks: {
                knock: 40,
                talk: 14,
                walk: 2,
                contingency: 1,
            },
            loading: false,
        }
    },
    watch: {
        $route: {
            async handler() {
                if (this.$route.path === '/' || this.$route.path === '/shotgun') {
                    this.getKTWC()
                }
            },
            immediate: true,
        }
    },
    created() {
        this.getAverageKTWC()
        this.getKTWC()
    },
    methods: {
        async getAverageKTWC() {
            // If last checked was less than 24hrs ago, use the cached blacklist
            if (localStorage.getItem('averageConversionChecked') && (new Date() - new Date(localStorage.getItem('blacklistChecked'))) < 24*60*60*1000) {
                this.store.averageConversion = JSON.parse(localStorage.getItem('averageConversion'))
            } else {
                this.store.averageConversion = (await this.axios.get(`${this.store.api}/controllers/knockToContingencyAverage`))?.data
                localStorage.setItem('averageConversion', JSON.stringify(this.store.averageConversion))
                localStorage.setItem('averageConversionChecked', new Date())
            }
        },
        async getKTWC() {
            if (this.loading) return
            this.loading = true
            try {
                // Try with timeout
                runWithTimeout(async () => {
                    let response = (await this.axios.get(`${this.store.api}/controllers/ktwcStats`))?.data
                    if (response?.valid === false) {
                        this.store.user = {}
                        window.localStorage.removeItem('user')
                        window.localStorage.removeItem('token')
                        window.localStorage.removeItem('email')
                        window.localStorage.removeItem('auth')
                        setCookie(`Authorization`, ``)
                    }
                    this.store.weeklyAverage = response.weeklyAverage
                    this.store.ktwc = response?.ktwc
                    this.store.user.shotgun = response?.shotgun || []
                }, 5000);

                let localEntries = await this.store.localDB.getEntries()
                console.log('localEntries', localEntries)

                // Consider filtering out entries that are out of the current date range
                this.store.localKtwc = {
                    knock: localEntries.filter(entry => entry?.ktwc?.knock).length,
                    talk: localEntries.filter(entry => entry?.ktwc?.talk).length,
                    walk: localEntries.filter(entry => entry?.ktwc?.walk).length,
                    contingency: localEntries.filter(entry => entry?.ktwc?.contingency).length,
                    contractSigned: localEntries.filter(entry => entry?.contractSigned).length,
                }
            } catch (e) {
                console.log('Error getting KTWC', e)
            }
            this.loading = false
        }
    },
    computed: {
        homepage() {
            return this.$route.path === '/'
        },
        average() {
            return this.store?.weeklyAverage?.conversion >= this.store.averageConversion
        },
        statusLight() {

            if (this.store.production) return {
                knock: 'border-gray-200 bg-red-50',
                talk: 'border-gray-200 bg-red-50',
                walk: 'border-gray-200 bg-red-50',
                contingency: 'border-gray-200 bg-red-50',

                average: {
                    knock: 'border-gray-200 bg-red-50',
                    talk: 'border-gray-200 bg-red-50',
                    walk: 'border-gray-200 bg-red-50',
                    contingency: 'border-gray-200 bg-red-50',
                }
            }

            // if (isNaN(this.store?.weeklyAverage?.conversion) || !this.store.averageConversion == null) return 'border-gray-400 bg-gray-50'
            // return this.average ? 'border-green-400 bg-green-50/50' : 'border-red-500 bg-red-50'

            return {
                knock: this.ktwc.knock >= this.benchmarks.knock ? 'border-green-400 bg-green-50' : 'border-gray-200 bg-red-50',
                talk: this.ktwc.talk >= this.benchmarks.talk ? 'border-green-400 bg-green-50' : 'border-gray-200 bg-red-50',
                walk: this.ktwc.walk >= this.benchmarks.walk ? 'border-green-400 bg-green-50' : 'border-gray-200 bg-red-50',
                contingency: this.ktwc.contingency >= this.benchmarks.contingency ? 'border-green-400 bg-green-50' : 'border-gray-200 bg-red-50',

                average: {
                    knock: this.averageKtwc?.knock?.toFixed?.(0) >= this.benchmarks.knock ? 'border-green-400 bg-green-50' : 'border-red-300 bg-red-50',
                    talk: this.averageKtwc?.talk?.toFixed?.(0) >= this.benchmarks.talk ? 'border-green-400 bg-green-50' : 'border-red-300 bg-red-50',
                    walk: this.averageKtwc?.walk?.toFixed?.(0) >= this.benchmarks.walk ? 'border-green-400 bg-green-50' : 'border-red-300 bg-red-50',
                    contingency: this.averageKtwc?.contingency?.toFixed?.(0) >= this.benchmarks.contingency ? 'border-green-400 bg-green-50' : 'border-red-300 bg-red-50',
                }
            }

        },
        ktwc() {
            return {
                knock: (this.store.ktwc.knock || 0) + (this.store.localKtwc.knock || 0),
                talk: (this.store.ktwc.talk || 0) + (this.store.localKtwc.talk || 0),
                walk: (this.store.ktwc.walk || 0) + (this.store.localKtwc.walk || 0),
                contingency: (this.store.ktwc.contingency || 0) + (this.store.localKtwc.contingency || 0),
            }
        },
        averageKtwc() {
            return {
                knock: (this.store?.weeklyAverage?.knock || 0),
                talk: (this.store?.weeklyAverage?.talk || 0),
                walk: (this.store?.weeklyAverage?.walk || 0),
                contingency: (this.store?.weeklyAverage?.contingency || 0),
            }
        }
    }
}
</script>

<style>

</style>
