<template>

    <iframe v-if="formUrl" class="w-full h-[calc(100vh-227px)] flex-grow flex flex-col" :src="formUrl" />

	<div v-else class="w-full flex-grow py-12 px-8 flex flex-col">
		<div class="flex flex-col justify-start items-start space-y-5">
			<!-- Owner Name -->
			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Homeowner's Name (as it appears in JobNimbus)</h3>
				<input v-model="formData.jnName" type="text" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.jnName }" />
				<span v-if="errors.jnName" class="text-red-500 text-sm"> Owner Name is required. </span>
			</div>

			<!-- What happened at this lead? -->
			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">What happened at this lead?</h3>
				<select v-model="formData.whatHappened" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.whatHappened }">
					<option value="">Select an option</option>
					<option value="Ran">Ran</option>
					<option value="Ran, no/one legger">Ran, no/one legger</option>
					<option value="Follow Up - not new lead">Follow Up - not new lead</option>
					<option value="Cancelled">Cancelled</option>
					<option value="Rescheduled">Rescheduled</option>
					<option value="No Show">No Show</option>
				</select>
				<span v-if="errors.whatHappened" class="text-red-500 text-sm"> Please select what happened at this lead. </span>
			</div>

			<!-- Did you demo? -->
			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Did you demo?</h3>
				<select v-model="formData.didYouDemo" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.didYouDemo }">
					<option value="">Select an option</option>
					<option value="Yes">Yes</option>
					<option value="No">No</option>
					<option value="Out of Scope">Out of Scope</option>
					<option value="Scheduled for later date">Scheduled for later date</option>
				</select>
				<span v-if="errors.didYouDemo" class="text-red-500 text-sm"> Please select if you demoed. </span>
			</div>

			<!-- Did you sign? -->
			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Did you sign...</h3>
				<p class="text-xs opacity-60">ONLY COUNT AS CONTRACTED WITH DEPOSIT OR APPROVED FINANCING</p>
				<select v-model="formData.didYouSign" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.didYouSign }">
					<option value="">Select an option</option>
					<option value="Contract">Contract</option>
					<option value="Contingency">Contingency</option>
					<option value="No">No</option>
				</select>
				<span v-if="errors.didYouSign" class="text-red-500 text-sm"> Please select if you signed. </span>
			</div>

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Contract Amount</h3>
				<p class="text-xs opacity-60">$ for contract signed (if nothing signed, enter 0) REQUIRED</p>
				<input v-model.number="formData.revenue" type="number" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.revenue }" />
				<span v-if="errors.revenue" class="text-red-500 text-sm"> Contract amount is required. </span>
			</div>

			<!-- Did you apply for financing? -->
			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Did you apply for financing?</h3>
				<select v-model="formData.appliedForFinancing" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.appliedForFinancing }">
					<option value="">Select an option</option>
					<option value="Not Needed">Not Needed</option>
					<option value="Yes">Yes</option>
				</select>
				<span v-if="errors.appliedForFinancing" class="text-red-500 text-sm"> Please select if financing was applied for. </span>
			</div>

			<!-- Was the homeowner approved? -->
			<div class="flex flex-col space-y-1 w-full" v-if="formData.appliedForFinancing === 'Yes'">
				<h3 class="text-sm opacity-70">ONLY ANSWER IF YOU APPLIED FOR FINANCING!!!!!</h3>
				<h4 class="text-sm opacity-70">Was the homeowner approved?</h4>
				<select v-model="formData.homeownerApproved" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.homeownerApproved }">
					<option value="">Select an option</option>
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
				<span v-if="errors.homeownerApproved" class="text-red-500 text-sm"> Please specify if the homeowner was approved. </span>
			</div>

			<!-- Contract amount if not approved -->
			<div class="flex flex-col space-y-1 w-full" v-if="formData.homeownerApproved === 'No'">
				<h3 class="text-sm opacity-70">IF NOT APPROVED for financing, what was the contract amount?</h3>
				<input
					v-model="formData.contractAmountIfNotApproved"
					type="number"
					class="px-3 py-2 border w-full"
					:class="{ 'border-red-500': errors.contractAmountIfNotApproved }"
				/>
				<span v-if="errors.contractAmountIfNotApproved" class="text-red-500 text-sm"> Please provide the contract amount if not approved. </span>
			</div>

			<!-- Why did they not sign today? -->
			<div v-if="formData.didYouSign === 'No'" class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Why did they not sign today?</h3>
				<div class="flex flex-col space-y-2">
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="Price too high" />
						Price too high
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="Collecting Estimates/Shopping Around" />
						Collecting Estimates/Shopping Around
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="Thinking About It" />
						Thinking About It
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="Not all decision makers present" />
						Not all decision makers present
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="GoodLeap Denial" />
						GoodLeap Denial
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="HO wanted to go through Insurance" />
						HO wanted to go through Insurance
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="None of the above" />
						None of the above
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="Free Roof Only" />
						Free Roof Only
					</label>
				</div>
				<span v-if="errors.notSignedReasons" class="text-red-500 text-sm"> Please select at least one reason why they did not sign. </span>
			</div>
		</div>

		<button
			@click="next"
			:class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`"
			class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-end mt-8"
		>
			Submit
		</button>

		<!-- <button
			@click="openForm"
			:class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`"
			class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-end mt-8"
		>
			openForm
		</button> -->

	</div>
</template>


<script>
import Swal from 'sweetalert2'
import Mango from '../../helpers/mango.js'
import Address from '../layout/address.vue'

export default {
	components: { Address },
	props: ['opportunity'],
	inject: ['store'],
	data() {
		return {
			savingOpportunity: false,
			address: {
				formatted: null,
			},
			errors: {},
            formUrl: null,
			formData: {
				jnName: this.opportunity?.jnName || '',
				whatHappened: this.opportunity?.whatHappened || '',
				didYouDemo: this.opportunity?.didYouDemo || '',
				didYouSign: this.opportunity?.didYouSign || '',
				revenue: this.opportunity?.revenue || 0,
				appliedForFinancing: this.opportunity?.appliedForFinancing || '',
				homeownerApproved: this.opportunity?.homeownerApproved || '',
				contractAmountIfNotApproved: this.opportunity?.contractAmountIfNotApproved || null,
				notSignedReasons: this.opportunity?.notSignedReasons || [],
			},
		}
	},
	watch: {
		savingOpportunity() {
			this.$emit('saving', this.savingOpportunity)
		},
	},
	methods: {
		validateForm() {

            this.errors = {}; // Reset errors

            const requiredFields = [
                { field: 'jnName', condition: !this.formData.jnName },
                { field: 'whatHappened', condition: !this.formData.whatHappened },
                { field: 'didYouDemo', condition: !this.formData.didYouDemo },
                { field: 'didYouSign', condition: !this.formData.didYouSign },
                { field: 'revenue', condition: isNaN(this.formData.revenue) || this.formData.revenue < 0 },
                { field: 'appliedForFinancing', condition: !this.formData.appliedForFinancing },
                { field: 'homeownerApproved', condition: this.formData.appliedForFinancing === 'Yes' && !this.formData.homeownerApproved },
                { field: 'contractAmountIfNotApproved', condition: this.formData.homeownerApproved === 'No' && !this.formData.contractAmountIfNotApproved },
                { field: 'notSignedReasons', condition: this.formData?.didYouSign === 'No' && this.formData.notSignedReasons.length === 0 }
            ];

            requiredFields.forEach(({ field, condition }) => {
                if (condition) {
                    this.errors[field] = true;
                }
            });

		},
        openForm() {
            const formPayload = new URLSearchParams();
            // formPayload.append('emailAddress', this.store.user.email);
            formPayload.append('entry.2075354376', this.store.user.branches[0].title);
            formPayload.append('entry.1556729737', this.store.user.title);
            formPayload.append('entry.1652929079', this.formData?.jnName);
            formPayload.append('entry.1282817332', this.formData?.whatHappened);
            formPayload.append('entry.15737956', this.formData?.didYouDemo);
            formPayload.append('entry.1790719457', this.formData?.didYouSign);
            formPayload.append('entry.714752872', this.formData?.revenue);
            formPayload.append('entry.463062150', this.formData?.appliedForFinancing);

            // Only append if homeownerApproved is relevant
            if (this.formData?.appliedForFinancing === 'Yes') {
                formPayload.append('entry.984905662', this.formData?.homeownerApproved);
            }

            // Only append if homeownerApproved was 'No'
            if (this.formData?.homeownerApproved === 'No') {
                formPayload.append('entry.1815684740', this.formData?.contractAmountIfNotApproved);
            }

            // Append multiple checkbox values
            (this.formData?.notSignedReasons || []).forEach(reason => {
                formPayload.append('entry.247610939', reason);
            });

            // https://docs.google.com/forms/d/e/1FAIpQLSd037eR6iAUYcd-XByzjV002L_RyIFBWji8RxPio0c3bmYDzw/viewform?usp=pp_url&entry.2075354376=Athens&entry.1556729737=Name&entry.1652929079=Home&entry.1282817332=Ran&entry.15737956=Yes&entry.247610939=Price+too+high&entry.247610939=Collecting+Estimates/Shopping+Around
            // https://docs.google.com/forms/d/e/1FAIpQLSd037eR6iAUYcd-XByzjV002L_RyIFBWji8RxPio0c3bmYDzw/viewform?usp=pp_url&emailAddress=colton.neifert%40gmail.com&entry.2075354376=Test+Branch&entry.1556729737=Colton+Neifert&entry.1652929079=CJ+Neifert&entry.1282817332=ran&entry.15737956=yes&entry.1790719457=contract&entry.714752872=10000&entry.463062150=not_needed
            let formUrl = `https://docs.google.com/forms/d/e/1FAIpQLScQzp0AnTvyvpNQcxk3mysPEr_pdmHaX3u5vRobAC27bbuyqg/viewform?usp=pp_url&${formPayload.toString()}`
            console.log('formUrl', formUrl)

            // window.open(formUrl, '_blank');
            this.formUrl = formUrl
        },
		async next() {
			this.validateForm()
			let invalidFields = Object.keys(this.errors)
			if (invalidFields?.length)
				return Swal.fire({
					icon: 'warning',
					text: `Please enter a value for ${invalidFields?.map(f => f.toTitleCase('camel')).join(', ')}.`,
				})
			this.savingOpportunity = true
			let timestamps = this.opportunity.timestamps || {}
			timestamps.revenue = this.formData.revenue ? new Date() : null
			await this.store.localDB.save({
                id: this.opportunity.id,
				...this.formData,
				timestamps,
			})
            this.savingOpportunity = false
            this.openForm()
            // this.$router.push(`/opportunities/${this.opportunity.id}/lead-result`)
			// this.$router.push(`/`)
		},
	}
}
</script>
