<template>
	<div ref="mapRef" class="w-full h-full" />
    <slot name="popups" />
</template>

<script setup>
import { mapboxAccessToken } from "../../../../config/config/settings.json"
import "mapbox-gl/dist/mapbox-gl.css"
import mapboxgl from "mapbox-gl"
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { onMounted, watch, ref, inject } from "vue"
import { useModel } from '../../helpers/model'
import { useRouter, useRoute } from 'vue-router'
import Supercluster from 'supercluster';

let breakpoints = inject('breakpoints')

const router = useRouter()
const route = useRoute()

const emit = defineEmits(['update:bounds', 'update:hoveredMarker', 'markersLoaded', 'update:route'])

const props = defineProps({
	markers: { type: Array },
	center: { type: Array },
	bbox: { type: Array },
	dark: { type: Boolean },
	hover: { type: Boolean },
	geolocate: { type: Boolean },
    hoveredMarker: {type: String},
    startDate: {type: Date}
});

let mapRef = ref(null)
let hoveredMarker = useModel(props, emit, 'hoveredMarker')


mapboxgl.accessToken = mapboxAccessToken

let map = {}
let markers = []

let colorStatusMap = {
    'closed': 'bg-red-500',
    'Lockout': 'bg-red-500',
    'open': 'bg-green-500',
    'featured': 'bg-green-500',
    'Insurance Accepted': 'bg-green-500',
    'Contract Signed': 'bg-green-500',
    'Contingency': 'bg-green-500',
    'pending': 'bg-yellow-400',
    'Soft Set': 'bg-yellow-400',
    'hidden': 'bg-orange-500',
    'Retail': 'bg-orange-500',
    'Skip': 'bg-blue-500',
    'No Answer': 'bg-blue-500',
    'Not Interested': 'bg-blue-500',
}

function setBounds() {
    const bounds = map.getBounds();
    emit('update:bounds', bounds)
}

function loadMarkers({moveEnd} = {}) {
    // Define the precedence order
    const statusOrder = ['bg-blue-500', 'bg-red-500', 'bg-orange-500', 'bg-yellow-400', 'bg-green-500'];

    // Clear existing markers
    markers.forEach(m => m.remove());
    markers = [];

    clusterIndex = new Supercluster({
        radius: 10,
        maxZoom: 16,
        map: props => ({ status: props.status }), // Pass down `status` for clustering
        reduce: (a, props) => {
            let statusIndex = statusOrder.indexOf(props.status);
            let currentIndex = statusOrder.indexOf(a)
            return statusIndex > currentIndex ? props.status : a
        },
    });

    clusterIndex.load(props.markers.map(marker => ({
        type: 'Feature',
        properties: { cluster: false, ...marker },
        geometry: { type: 'Point', coordinates: marker.coordinates }
    })));


    // Get clusters for the current map bounds and zoom level
    const clusters = clusterIndex.getClusters(map.getBounds().toArray().flat(), map.getZoom());

    clusters.forEach(cluster => {
        const { cluster: isCluster, point_count: pointCount, status } = cluster.properties;

        // console.log('cluster', cluster)

        if (isCluster) {

            // This is a cluster, so we create a different type of marker
            const el = document.createElement('div');
            el.className = 'marker cluster-marker ';
            el.className += colorStatusMap[status]
            el.innerText = pointCount;

            const mkr = new mapboxgl.Marker(el)
                .setLngLat(cluster.geometry.coordinates)
                .addTo(map);

            markers.push(mkr);
        } else {

            const marker = cluster.properties;
            // This is a single point, create your normal marker
            const el = document.createElement('div');
            el.className = 'marker text-2xs aspect-square flex items-center justify-center text-white rounded-full text-center p-2 font-semibold border border-white ';
            el.className += colorStatusMap[marker.status]

            let mkr = new mapboxgl.Marker(el)
            .setLngLat(marker.coordinates)
            .setPopup(
                new mapboxgl.Popup({
                    maxWidth: '20rem',
                    closeButton: false,
                    offset: 0,
                })
                .setHTML(marker.popup || '')
            )
            .addTo(map);

            mkr.id = marker.id
            mkr.isCluster = isCluster
            mkr.collection = marker.collection
            markers.push(mkr)

            // Open popup on hover
            if (props.hover) {
                el.addEventListener('mouseenter', () => hoveredMarker.value = mkr.id);
                el.addEventListener('mouseleave', () => hoveredMarker.value = null);

                el.addEventListener('click', () => {if (breakpoints.xs) {
                    let path = `/${mkr.collection}/${mkr.id}`
                    window.open(path, "_blank") || window.location.replace(path);
                }});
            }
        }
    });

    if (!moveEnd) emit('markersLoaded')
}


watch(() => props.dark, () => {
    map.setStyle(props.dark ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10')
})

// watch(() => props.center, () => map.flyTo({center: props.center, zoom: 7}))
watch(() => props.bbox, () => map.fitBounds(props.bbox, {duration:0}))

watch(() => props.hoveredMarker, (n,o) => {

    markers.filter(m => m?.isCluster === false).forEach(m => {
        let el = m.getElement()
        el.className = el.className.replace('border-gray-200', '')
        m.getPopup().remove()
    })

    if (n) {
        let marker = markers.find(m => m?.id == n)
        if (marker) {
            let el = marker.getElement()
            el.className += ' border-gray-200'
            marker.togglePopup()
        }
    }

})

watch(() => props.markers, () => loadMarkers())

let clusterIndex;

const fitBounds = ({duration} = {duration: 1500}) => {

    if (!props.markers?.length) return

    // Create a bounding box for the markers
    const bounds = new mapboxgl.LngLatBounds();
    props.markers.forEach(marker => {
        bounds.extend(marker.coordinates);
    });

    map.fitBounds(bounds, {
        padding: {top: 100, bottom: 100, left: 100, right: 100},
        duration
    });

}

const drawRouteLine = () => {
    // Extract coordinates from the markers
    let coordinates = [...props.markers]
        // .filter(marker => marker.coordinates && !marker.cluster)
        .sort((a, b) => a.created > b.created ? 1 : -1)
        // .filter(marker => new Date(marker.created) > new Date(props.startDate))
        .map(marker => marker.coordinates)
        .filter(coord => coord) // Ensure coordinates exist

    if (coordinates.length < 2) return; // Need at least two points to draw a line
    // if (coordinates.length > 25) coordinates = coordinates.splice(0,25)
    console.log('coordinates.length', coordinates.length)

    // Create a GeoJSON LineString
    const routeGeoJSON = {
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: coordinates
        },
        properties: {}
    };

    // Check if route layer already exists, and remove it if so
    if (map.getSource('route')) {
        map.removeLayer('route-line');
        map.removeSource('route');
    }

    // Add the route as a source and layer
    map.addSource('route', {
        type: 'geojson',
        data: routeGeoJSON
    });

    map.addLayer({
        id: 'route-line',
        type: 'line',
        source: 'route',
        layout: {
            'line-join': 'round',
            'line-cap': 'round'
        },
        paint: {
            'line-color': '#ff6600',  // Customize line color
            'line-width': 4           // Customize line thickness
        }
    });
}

const removeRouteLine = () => {
    if (map.getSource('route')) {
        map.removeLayer('route-line');
        map.removeSource('route');
        emit('update:route', null)
    }
};

const getOptimalRoute = async () => {
    if (map.getSource('route')) return;

    let coordinates = [...props.markers]
        .sort((a, b) => a.created > b.created ? 1 : -1)
        .map(marker => marker.coordinates)
        .filter(coord => coord);

    if (coordinates.length < 2) return;

    console.log('coordinates?.length', coordinates?.length);

    const batchSize = 25;
    const batches = [];
    let totalDistance = 0;
    let totalDuration = 0;
    let combinedGeometry = {
        type: 'LineString',
        coordinates: []
    };

    // Split coordinates into batches of <= 25
    for (let i = 0; i < coordinates.length; i += batchSize - 1) {
        // Ensure overlapping points to maintain route continuity
        const batch = coordinates.slice(i, i + batchSize);
        batches.push(batch);
    }

    const fetchRouteBatch = async (batch) => {
        const coordinatesStr = batch.map(coord => coord.join(',')).join(';');
        const zoom = map.getZoom();
        const overview = zoom > 8 ? 'full' : 'simplified';

        const apiUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinatesStr}?geometries=geojson&overview=${overview}&access_token=${mapboxAccessToken}`;

        const response = await fetch(apiUrl);
        return response.json();
    };

    try {
        // Fetch all route batches
        const results = await Promise.all(batches.map(fetchRouteBatch));

        results.forEach(data => {
            if (data.routes && data.routes.length) {
                const route = data.routes[0];
                totalDistance += route.distance;
                totalDuration += route.duration;
                combinedGeometry.coordinates.push(...route.geometry.coordinates);
            }
        });

        emit('update:route', {
            distance: totalDistance,
            duration: totalDuration
        });

        // Draw the complete route
        const routeGeoJSON = {
            type: 'Feature',
            geometry: combinedGeometry,
            properties: {}
        };

        removeRouteLine();

        map.addSource('route', {
            type: 'geojson',
            data: routeGeoJSON
        });

        map.addLayer({
            id: 'route-line',
            type: 'line',
            source: 'route',
            layout: {
                'line-join': 'round',
                'line-cap': 'round'
            },
            paint: {
                'line-color': '#007aff',
                'line-width': 3
            }
        });

    } catch (error) {
        console.error('Error fetching optimal route:', error);
    }
};

defineExpose({
    fitBounds, 
    drawRouteLine, 
    removeRouteLine, 
    getOptimalRoute,
    map: () => map,
    flyTo: (options) => map.flyTo(options)
})

onMounted(() => {

    map = new mapboxgl.Map({
        container: mapRef.value, // container ID
        style: props.dark ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10',
        center: props.center || [-95.938458, 38.1462244], // starting position [lng, lat]
        zoom: 3, // starting zoom
        attributionControl: false
    });

    // map.addControl(
    //     new MapboxGeocoder({
    //         accessToken: mapboxgl.accessToken,
    //         mapboxgl
    //     })
    // );

    // let geolocate = new mapboxgl.GeolocateControl({
    //     positionOptions: {
    //         enableHighAccuracy: true
    //     },
    //     fitBoundsOptions: {
    //         maxZoom: 9
    //     },
    //     trackUserLocation: true,
    //     showUserHeading: true
    // })
    // map.addControl(geolocate);

    // const nav = new mapboxgl.NavigationControl();
    // map.addControl(nav, 'top-right');

    map.on('moveend', () => {
        setBounds()
        loadMarkers({moveEnd: true})
    });

	map.on("load", () => {

        if (props.geolocate) geolocate.trigger()
        loadMarkers()

        fitBounds()

	});

    if (route.query.startSearch) document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].focus()

});
</script>

<style lang="postcss">
.mapboxgl-map {
    font: inherit!important;
}

.mapboxgl-ctrl-top-right {
    z-index: 30;
}

.mapboxgl-ctrl,
.mapboxgl-ctrl-icon,
.mapboxgl-ctrl-compass,
.mapboxgl-ctrl-zoom-out,
.mapboxgl-ctrl-geolocate,
.mapboxgl-ctrl-geocoder--input {
    @apply dark:bg-gray-800 dark:!border-t-gray-600 dark:!text-gray-400
}

.mapboxgl-ctrl-geocoder--input,
.mapboxgl-ctrl-group {
    @apply rounded overflow-hidden
}

.mapboxgl-popup-anchor-right > .mapboxgl-popup-tip {
    @apply dark:!border-l-gray-800
}

.mapboxgl-popup-anchor-left > .mapboxgl-popup-tip {
    @apply dark:!border-r-gray-800
}

.mapboxgl-popup-anchor-top-left > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
    @apply dark:!border-b-gray-800
}

.mapboxgl-popup-anchor-bottom-left > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip {
    @apply dark:!border-t-gray-800
}

.mapboxgl-popup-anchor-top-right > .mapboxgl-popup-content > div {
    @apply !rounded-tr-none
}
.mapboxgl-popup-anchor-top-left > .mapboxgl-popup-content > div {
    @apply !rounded-tl-none
}
.mapboxgl-popup-anchor-bottom-right > .mapboxgl-popup-content > div {
    @apply !rounded-br-none
}
.mapboxgl-popup-anchor-bottom-left > .mapboxgl-popup-content > div {
    @apply !rounded-bl-none
}

.mapboxgl-popup {
    width: 100%;
    z-index: 20;
    display: none;
}

@media (min-width: 475px) {
    .mapboxgl-popup {
        display: flex;
    }
}

.mapboxgl-popup-content {
	text-align: center;
	font-family: inherit!important;
    padding: 0;
    border-radius: 0;
    background: transparent;
    width: 100%;
}
.cluster-marker {
    width: 24px;
    @apply text-2xs aspect-square flex items-center justify-center text-white rounded-full text-center p-1 font-semibold border border-white
}

</style>
