<template>
    <div class="w-full relative">
        <div class="border-b sticky top-0 bg-white z-40">
            <div class="flex items-center justify-center px-6 h-16">
                <!-- <button class="text-blue-500" @click="$router.go(-1)"><i class="fa fa-chevron-left mr-2" />Back</button> -->
                <div class="text-xl">{{ activeTab === 'my' ? 'My Opportunities' : 'All Opportunities' }}</div>
                <!-- <div class="opacity-0 select-none"><i class="fa fa-chevron-left mr-2" />Back</div> -->
            </div>
            <!-- Tab navigation when user level > 1 -->
            <div v-if="store.user?.level > 1" class="flex border-b w-full justify-center">
                <button @click="activeTab = 'my'" class="px-4 py-2 text-sm font-medium"
                    :class="activeTab === 'my' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500 hover:text-gray-700'">
                    My Opportunities
                </button>
                <button @click="activeTab = 'all'" class="px-4 py-2 text-sm font-medium"
                    :class="activeTab === 'all' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500 hover:text-gray-700'">
                    All Opportunities
                </button>
            </div>
            <div v-if="activeTab == 'my'" class="p-4 shadow-lg flex items-center space-x-4">
                <label class="relative flex items-center flex-grow">
                    <input v-model="search" placeholder="Search for an Opportunity"
                        class="w-full border border-gray-300 rounded-full outline-blue-400 dark:outline-blue-500/40 px-3 py-2 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-600" />
                    <button
                        class="fa fa-times absolute right-2 w-6 h-6 rounded-full bg-gray-100 text-gray-500 dark:bg-gray-700 text-sm"
                        @click="search = ''" v-if="search" />
                </label>
                <button @click="showFilters = true"><i class="fal fa-cog text-xl text-gray-500" /></button>
            </div>
        </div>

        <Modal :active="showFilters" @hide="showFilters = false" class="filters">
            <h2 class="text-2xl text-center">Advanced Filters</h2>
            <button @click="filters = baseFilters; showFilters = false"
                class="text-red-500 text-center w-full !mt-1 underline">
                Clear
            </button>

            <!-- <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Exact Address</div>
                <VagueAddress v-model="filters.address" />
            </div> -->

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Number</div>
                <input v-model="filters.streetNumber" placeholder="Number" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Street</div>
                <input v-model="filters.street" placeholder="Street" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">City</div>
                <input v-model="filters.city" placeholder="City" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Owner Last Name</div>
                <input v-model="filters.owner" placeholder="Last Name" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Revenue</div>
                <div class="flex items-center space-x-4">
                    <input v-model.number="filters.revenueMin" placeholder="Min" />
                    <input v-model.number="filters.revenueMax" placeholder="Max" />
                </div>
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Estimate</div>
                <div class="flex items-center space-x-4">
                    <input v-model.number="filters.estimateMin" placeholder="Min" />
                    <input v-model.number="filters.estimateMax" placeholder="Max" />
                </div>
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Last Updated Date Range
                </div>
                <div class="text-xs flex space-x-4 items-center">
                    <input type="date" v-model="filters.rangeStart" class="border border-gray-300 rounded px-2 py-1" />
                    <!-- <span class="mx-2">to</span> -->
                    <input type="date" v-model="filters.rangeEnd" class="border border-gray-300 rounded px-2 py-1" />
                </div>
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Only Contract Signed</div>
                <Toggle v-model="filters.contractSigned" :small="false" background="bg-gray-100 dark:bg-gray-700" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Only Insurance Accepted
                </div>
                <Toggle v-model="filters.insuranceAccepted" :small="false" background="bg-gray-100 dark:bg-gray-700" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Status</div>
                <p class="text-xs opacity-50 mb-2">Please note the status only contains one value, the highest in the
                    opportunity's lifecycle.</p>
                <select v-model="filters.status">
                    <option :value="null">All Statuses</option>
                    <option v-for="status in statusOptions" :key="status" :value="status">{{ status }}</option>
                </select>
            </div>

            <div class="flex justify-center">
                <button @click="showFilters = false"
                    class="bg-red-500 text-white font-semibold px-3 py-2 rounded mx-auto">Apply</button>
            </div>
        </Modal>

        <!-- Show My Opportunities content when activeTab is 'my' or user level <= 1 -->
        <div v-if="activeTab === 'my'">
            <Mango v-if="userId" :infinite="true" collection="opportunities" :id="null" :query="{
					search: {
						//authorId: userId,
						or: [{ authorId: userId }, { shotgun: userId }],
						...filterSearch,
					},
					sort: { updated: -1 },
					limit: 250,
					fields: [
						'address',
						'ownerInfo',
						'selfie',
						'contractSigned',
						'insuranceAccepted',
						'timestamps',
						'revenue',
						'estimate',
						'inspection',
						'discussion',
						'created',
						'status',
                        'retail'
					],
				}" @update:data="setOpportunities" v-slot="{ loading }" class="w-full" ref="mango">
                <Spinner v-if="!opportunities.length && loading" :small="true" class="w-32 h-32 mt-32 mx-auto" />
                <div v-else-if="!filteredOpportunities.length" class="text-center text-3xl opacity-20 font-bold mt-16">
                    No Opportunities</div>
                <div v-else class="w-full flex flex-col divide-y max-w-[100vw] overflow-hidden">
                    <Opportunity v-for="o in filteredOpportunities" :key="o.id" :op="o" @deleted="removeOp" />
                </div>
            </Mango>
        </div>

        <!-- Show All Opportunities when activeTab is 'all' and user level > 1 -->
        <AllOpportunitiesComponent v-if="activeTab === 'all' && store.user && store.user.level > 1" :app="true" />
    </div>
</template>

<script>
import Address from '../layout/address.vue'
import Modal from '../layout/modal.vue'
import Toggle from '../partials/toggle.vue'
import VagueAddress from '../layout/vagueAddress.vue'
import Opportunity from '../layout/opportunity.vue'
import dayjs from 'dayjs'
import AllOpportunitiesComponent from '../../../../ui/src/pages/opportunities.vue'

let baseFilters = {
	streetNumber: null,
	street: null,
	city: null,
	owner: null,
	contractSigned: null,
	insuranceAccepted: null,
	revenueMin: null,
	revenueMax: null,
	estimateMin: null,
	estimateMax: null,
	address: {
		formatted: null,
	},
	status: null,
	rangeStart: dayjs().subtract(1, 'months').format('YYYY-MM-DD'),
	rangeEnd: dayjs().format('YYYY-MM-DD'),
}

export default {
	components: { Toggle, Modal, Address, VagueAddress, Opportunity, AllOpportunitiesComponent },
	inject: ['store'],
	data() {
		return {
			opportunities: [],
			showFilters: false,
			search: '',
			baseFilters,
			filters: JSON.parse(JSON.stringify(baseFilters)),

			startX: 0,
			startY: 0,
			isScrollLocked: false,

			statusOptions: ['Contract Signed', 'Insurance Accepted', 'Contingency', 'Soft Set', 'Not Interested', 'Lockout', 'No Answer'],
			activeTab: 'my',
		}
	},
	async mounted() {
		let localOpportunities = (await this.store.localDB.getEntries())?.filter((o) => !!o.address)
		localOpportunities.forEach((o) => {
			if (o.selfie) o.selfie = { url: URL.createObjectURL(o.selfie) }
			if (o.address) o.address.address = `${o.address.number} ${o.address.street}`
		})
		if (localOpportunities?.length) {
			let newOpportunities = localOpportunities.filter((o) => !this.opportunities.find((l) => l.id == o.id))
			this.opportunities = [...newOpportunities, ...this.opportunities]
		}
	},
	activated() {
		console.log('activated!')
		// this.$refs.mango.init()
		// this.mounted()
	},
	methods: {
		dayjs,
		setOpportunities(opportunities) {
			// for (let o of opportunities) {
			//     if (typeof o.ownerInfo != "object") o.ownerInfo = {}
			//     if (!o.ownerInfo.bestTime) o.ownerInfo.bestTime = null
			//     if (!o.ownerInfo.bestDate) o.ownerInfo.bestDate = null
			// }
			if (this.filterSearch) this.opportunities = opportunities
			else this.opportunities = [...this.opportunities, ...opportunities]
		},
		removeOp(id) {
			console.log(id, 'fired')
			this.opportunities.splice(
				this.opportunities.findIndex((o) => o.id == id),
				1
			)
			if (this.store?.currentOpportunityPath?.includes?.(id)) this.store.currentOpportunityPath = null
		},
	},
	computed: {
		userId() {
			return this.store.user?.id
		},
		filteredOpportunities() {
			console.log('filteredOpportunities')
			if (!this.search) return this.opportunities
			return this.opportunities.filter((opportunity) => {
				let content =
					`${opportunity.address?.address} ${opportunity.address?.city} ${opportunity.ownerInfo?.firstName} ${opportunity.ownerInfo?.lastName} ${opportunity.ownerInfo?.goesBy}`.toLowerCase()
				let search = this.search?.toLowerCase()
				return content.includes(search)
			})
		},
		filterSearch() {
			let search = {}
			if (this.filters.address?.id) search['address.id'] = this.filters.address.id
			if (this.filters?.streetNumber) search['address.number'] = this.filters.streetNumber
			if (this.filters?.street) search['address.street'] = { $regex: this.filters.street, $options: 'i' }
			if (this.filters?.city) search['address.city'] = { $regex: this.filters.city, $options: 'i' }
			if (this.filters?.owner) search['ownerInfo.lastName'] = { $regex: this.filters.owner, $options: 'i' }
			if (this.filters?.status) search.status = this.filters.status
			if (this.filters?.contractSigned) search.contractSigned = { $ne: null }
			if (this.filters?.insuranceAccepted) search.insuranceAccepted = { $ne: null }
			if (this.filters?.revenueMin) search.revenue = { $gte: this.filters.revenueMin }
			if (this.filters?.revenueMax) search.revenue = { ...(search?.revenue || {}), $lte: this.filters.revenueMax }
			if (this.filters?.estimateMin) search.estimate = { $gte: this.filters.estimateMin }
			if (this.filters?.estimateMax) search.estimate = { ...(search?.estimate || {}), $lte: this.filters.estimateMax }
			if (this.filters?.rangeStart) search.compareUpdated = { greaterThan: dayjs(this.filters.rangeStart) }
			if (this.filters?.rangeEnd)
				search.compareUpdated
					? (search.compareUpdated.lessThan = dayjs(this.filters.rangeEnd).add(1, 'day'))
					: { lessThan: dayjs(this.filters.rangeEnd).add(1, 'day') }
			return search
		},
	},
}
</script>

<style lang="postcss" scoped>
.filters input,
.filters select {
	@apply border border-gray-300 rounded outline-blue-400 dark:outline-blue-500/40 px-3 py-2 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-600 w-full;
}
</style>
