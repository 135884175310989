let formatAddress = function (place) {

    // TomTom
    let coordinates = {
        lat: place?.position?.lat || place?.position?.split?.(',')[0],
        lng: place?.position?.lon || place?.position?.split?.(',')[1],
    }
    if (coordinates.lat) coordinates.lat = Number(coordinates.lat)
    if (coordinates.lng) coordinates.lng = Number(coordinates.lng)

    return {
        id: place?.id,
        number: place?.address?.streetNumber,
        street: place?.address?.streetName,
        address: `${place?.address?.streetNumber} ${place?.address?.streetName}`,
        city: place?.address?.municipality,
        state: place?.address?.countrySubdivisionName,
        stateCode: place?.address?.countrySubdivisionCode,
        zip: place?.address?.postalCode,
        country: place?.address?.country,
        formatted: place?.address?.freeformAddress,
        coordinates
    }

    // // LocationIQ
    // return {
    //     id: place.place_id,
    //     number: place?.address?.house_number,
    //     street: place?.address?.road,
    //     address: `${place?.address?.house_number} ${place?.address?.road}`,
    //     city: place?.address?.city,
    //     state: place?.address?.state,
    //     stateCode: place?.address?.state,
    //     zip: place?.address?.postcode,
    //     country: place?.country,
    //     formatted: place.display_name,
    //     coordinates: {
    //         lat: place?.lat,
    //         lng: place?.lon,
    //     },
    // }

    // //  Radar
    // return {
    //     id: place?.formattedAddress?.toLowerCase()?.trim()?.replace?.(/[^a-zA-Z0-9\s]/g, '')?.replace?.(/\s/g, '-'),
    //     number: place?.number,
    //     street: place?.street,
    //     address: place?.addressLabel,
    //     city: place?.city,
    //     state: place?.state,
    //     stateCode: place?.stateCode,
    //     zip: place?.postalCode,
    //     country: place?.country,
    //     formatted: place.formattedAddress,
    //     coordinates: {
    //         lat: place?.latitude,
    //         lng: place?.longitude,
    //     },
    // }
}

export default formatAddress
