<template>

    <div class="w-full flex-grow py-12 px-8 flex flex-col">

		<div class="flex flex-col justify-start items-start space-y-5">

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Owner First Name</h3>
				<input v-model="formData.firstName" type="text" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Owner Last Name</h3>
				<input v-model="formData.lastName" type="text" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Owner Goes By</h3>
				<input v-model="formData.goesBy" type="text" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-2 w-full">
				<h3 class="text-sm opacity-70">Verify Address</h3>
                <div class="border bg-gray-50 rounded-lg p-4">
                    <Address v-model="address" />
                </div>
			</div>

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Phone Number</h3>
				<input v-model="formData.phoneNumber" type="tel" class="px-3 py-2 border w-full">
			</div>
			<label class="flex space-x-4 items-center">
				<input v-model="formData.callOrText" type="checkbox">
				<div>Cell phone / text message?</div>
			</label>


			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Email Address</h3>
				<input v-model="formData.emailAddress" type="email" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Best Time to Call</h3>
				<input v-model="formData.bestTime" type="time" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Best Date to Return</h3>
				<input v-model="formData.bestDate" type="date" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Notes</h3>
				<textarea v-model="formData.notes" class="px-3 py-2 border w-full resize-none" rows="5"></textarea>
			</div>

		</div>

		<button @click="next" :class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`" class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-end mt-8">
            Submit
        </button>

    </div>

</template>

<script>
import Swal from 'sweetalert2'
import Mango from '../../helpers/mango.js'
import Address from '../layout/address.vue'

export default {
    components: { Address },
    props: ['opportunity'],
    inject: ['store'],
    data() {
        return {
            savingOpportunity: false,
			address: {
                formatted: null
            },
			formData: {
                firstName: null,
                lastName: null,
                goesBy: null,
				phoneNumber: null,
				callOrText: false,
				emailAddress: null,
				bestTime: null,
				bestDate: null,
				notes: null,
			}
        }
    },
    watch: {
        savingOpportunity() {
            this.$emit('saving', this.savingOpportunity)
        }
    },
    methods: {
        async next() {
            let softSet = this.opportunity?.inspection?.toLowerCase?.() === 'soft set' || this.opportunity?.discussion?.toLowerCase?.() === 'soft set'
            let contingency = this.opportunity?.inspection?.toLowerCase?.() === 'contingency'
            if ((softSet || contingency) && (!this.formData?.lastName || !this.formData?.firstName)) return Swal.fire({icon: 'warning', text: `Please enter the owner's first and last name for this ${contingency ? 'contingency' : 'soft set'}.`})
            this.savingOpportunity = true
            await this.store.localDB.save({ id: this.opportunity.id, address: this.address, ownerInfo: this.formData })
            this.opportunity.address = this.address
            this.opportunity.ownerInfo = this.formData
            this.savingOpportunity = false
            console.log('this.$route.query?.next', this.$route.query?.next)
            if (this.$route.query?.next) return this.$router.push(this.$route.query?.next)
            if (softSet) {
                this.store.currentOpportunityPath = null
                return this.$router.push(`/`)
            }
            this.$router.push(`/opportunities/${this.opportunity.id}/ink`)
        }
    },
	created() {
		if (this.opportunity.address?.formatted) this.address = this.opportunity.address
		const savedInfo = this.opportunity?.ownerInfo || {}
        this.formData = {...this.formData, ...savedInfo}
		// if (savedInfo?.ownerName) this.formData.ownerName = savedInfo.ownerName
		// if (savedInfo?.phoneNumber) this.formData.phoneNumber = savedInfo.phoneNumber
		// if (savedInfo?.callOrText) this.formData.callOrText = savedInfo.callOrText
		// if (savedInfo?.emailAddress) this.formData.emailAddress = savedInfo.emailAddress
		// if (savedInfo?.bestTime) this.formData.bestTime = savedInfo.bestTime
		// if (savedInfo?.bestDate) this.formData.bestDate = savedInfo.bestDate
		// if (savedInfo?.notes) this.formData.notes = savedInfo.notes
	}
}
</script>
