<template>
    <div class="flex-grow h-[calc(100vh-5rem)] w-full flex flex-col relative">
        <!-- Filters -->
        <div class="bg-white dark:bg-gray-800 border-b absolute top-0 left-0 z-10 w-full">

            <!-- Filter Toggle Button -->
            <button @click="showFilters = !showFilters"
                class="absolute z-50 right-4 bg-white dark:bg-gray-800 shadow-xl rounded-full p-2 border hover:bg-gray-50 transition-colors text-xs w-8 h-8 flex items-center justify-center"
                :class="showFilters ? '-bottom-6' : '-bottom-12'">
                <i class="fas" :class="showFilters ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </button>

            <transition name="slide">
                <div v-show="showFilters" class="p-4 flex flex-wrap gap-4 items-center text-xs">

                    <div class="text-2xl text-center w-full">Map Filters</div>

                    <!-- View Toggle -->
                    <!-- <div class="flex bg-white shadow-xl rounded-xl divide-x overflow-hidden border">
                        <button v-for="view in ['all', 'mine']" :key="view"
                            class="px-4 py-2 text-xs flex items-center gap-2"
                            :class="{ 'text-blue-500 bg-blue-50': selectedView === view }" @click="selectedView = view">
                            <i class="fas" :class="view === 'mine' ? 'fa-user' : 'fa-building'"></i>
                            {{ view === 'mine' ? 'Me' : 'Branch' }}
                        </button>
                    </div> -->



                    <!-- Age Filter -->
                    <div class="flex bg-white shadow-xl rounded-xl divide-x overflow-hidden border">
                        <button v-for="age in ageOptions" :key="age.value" class="px-3 py-2 text-xs"
                            :class="{ 'text-blue-500 bg-blue-50': selectedAge === age.value }"
                            @click="selectedAge = age.value">
                            {{ age.label }}
                        </button>
                    </div>

                    <!-- Loading -->
                    <div v-if="loading" class="ml-auto flex items-center gap-2 text-xs text-gray-500">
                        <i class="fas fa-spinner fa-spin"></i>
                        Loading...
                    </div>
                    <div v-else class="ml-auto text-xs text-gray-500">
                        {{ filteredMarkers.length }} result{{ filteredMarkers.length !== 1 ? 's' : '' }}
                    </div>

                    <!-- Status Filter -->
                    <!-- <div class="flex bg-white shadow-xl rounded-xl divide-x overflow-hidden border">
                        <button v-for="status in statusOptions" :key="status.value"
                            class="px-3 py-2 text-xs flex items-center gap-2"
                            :class="{ 'text-blue-500 bg-blue-50': selectedStatuses.includes(status.value) }"
                            @click="toggleStatus(status.value)">
                            <span :class="`w-2 h-2 rounded-full ${status.color}`"></span>
                            {{ status.label }}
                        </button>
                    </div> -->

                    <!-- KTWC Filters -->
                    <div class="flex bg-white shadow-xl rounded-xl overflow-hidden border">
                        <div class="flex flex-wrap p-2 gap-2">
                            <button v-for="filter in ktwcFilters" :key="filter.value"
                                class="px-3 py-1.5 text-xs flex items-center gap-2 rounded border"
                                :class="{ 'bg-blue-50 text-blue-500': selectedKtwcFilters.includes(filter.value), 'border-gray-100': !selectedKtwcFilters.includes(filter.value) }"
                                @click="toggleKtwcFilter(filter.value)">
                                <div class="flex items-center justify-center w-4 h-4 border rounded transition-colors"
                                    :class="{ 'bg-blue-500 border-blue-500': selectedKtwcFilters.includes(filter.value), 'border-gray-300': !selectedKtwcFilters.includes(filter.value) }">
                                    <i v-if="selectedKtwcFilters.includes(filter.value)"
                                        class="fas fa-check text-white text-[10px]"></i>
                                </div>
                                {{ filter.label }}
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <!-- Map -->
        <div class="flex-grow relative">
            <Mapbox v-if="filteredMarkers?.length" :markers="filteredMarkers" :hover="true" ref="map"
                @markersLoaded="handleMarkersLoaded" class="w-full h-full" />
            <div v-else-if="loading" class="w-full h-full flex items-center justify-center">
                <Spinner />
            </div>
            <div v-else class="w-full h-full flex items-center justify-center text-gray-500">No opportunities found
            </div>
        </div>
    </div>
</template>

<script>
import Mapbox from '../layout/mapbox.vue'
import Mango from '../../helpers/mango'
import debounce from 'lodash/debounce'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { mapboxAccessToken } from '../../../../config/config/settings.json'

const CACHE_KEY = 'map_opportunities_cache'
const THREE_MONTHS = 90 * 24 * 60 * 60 * 1000 // 90 days in milliseconds

export default {
    name: 'MapView',
    components: { Mapbox },
    inject: ['store'],
    data() {
        return {
            markers: [],
            loading: false,
            lastChecked: null,
            opportunityCache: new Map(),
            selectedView: 'mine',
            selectedStatuses: ['all'],
            selectedAge: 'three_months',
            selectedKtwcFilters: [],
            geocoder: null,
            statusOptions: [
                { value: 'all', label: 'All', color: 'bg-blue-400' },
                { value: 'ctgy', label: 'Ctgy', color: 'bg-green-500' },
                { value: 'ss', label: 'Soft Set', color: 'bg-yellow-500' },
                { value: 'retail', label: 'Retail', color: 'bg-orange-500' },
            ],
            ageOptions: [
                { value: 'three_months', label: '3 Mo' },
                { value: 'thirty_days', label: '30 Days' },
                { value: 'two_weeks', label: '2 Weeks' },
                { value: 'one_week', label: '1 Week' },
            ],
            ktwcFilters: [
                { value: 'knock', label: 'Knock', color: 'bg-purple-500' },
                { value: 'talk', label: 'Talk', color: 'bg-indigo-500' },
                { value: 'walk', label: 'Walk', color: 'bg-pink-500' },
                { value: 'contingency', label: 'Contingency', color: 'bg-teal-500' },
                { value: 'insuranceAccepted', label: 'Approved', color: 'bg-cyan-500' },
                { value: 'contractSigned', label: 'Signed', color: 'bg-emerald-500' },
                { value: 'estimate', label: 'Estimated', color: 'bg-amber-500' },
                { value: 'softSet', label: 'Soft Set', color: 'bg-yellow-500' },
            ],
            showFilters: true,
        }
    },
    computed: {
        ageThreshold() {
            const now = new Date()
            switch (this.selectedAge) {
                case 'one_week':
                    return new Date(now - 7 * 24 * 60 * 60 * 1000)
                case 'two_weeks':
                    return new Date(now - 14 * 24 * 60 * 60 * 1000)
                case 'thirty_days':
                    return new Date(now - 30 * 24 * 60 * 60 * 1000)
                case 'three_months':
                default:
                    return new Date(now.setMonth(now.getMonth() - 3))
            }
        },
        filteredMarkers() {
            if (!this.markers.length) return []

            const threshold = this.ageThreshold

            return this.markers.filter((marker) => {
                // Filter by ownership
                if (this.selectedView === 'mine') {
                    let userId = this.store?.user?.id
                    const isAuthor = marker.author?.id === userId
                    const isShotgun = marker.shotgun?.some((s) => s.id === userId)
                    if (!isAuthor && !isShotgun) return false
                }

                // Filter by status
                if (!this.selectedStatuses.includes('all')) {
                    const statusMap = {
                        ctgy: ['featured', 'Insurance Accepted', 'Contract Signed', 'Contingency'],
                        retail: ['Retail'],
                        ss: ['Soft Set'],
                        closed: ['closed', 'Lockout'],
                    }

                    const matchesStatus = this.selectedStatuses.some(selectedStatus =>
                        statusMap[selectedStatus]?.includes(marker.status)
                    )

                    if (!matchesStatus) return false
                }

                // Filter by KTWC fields
                if (this.selectedKtwcFilters.length > 0) {
                    const ktwc = marker.ktwc || {}
                    const isSoftSet = marker.discussion === 'soft set' || marker.inspection === 'soft set'

                    const matchesKtwc = this.selectedKtwcFilters.every(filter => {
                        if (filter === 'softSet') {
                            return isSoftSet
                        }
                        return (!!ktwc[filter] || !!marker[filter])
                    })

                    if (!matchesKtwc) return false
                }

                // Filter by age
                if (marker.updated) {
                    const updatedDate = new Date(marker.updated)
                    if (updatedDate < threshold) {
                        return false
                    }
                }

                return true
            })
        },
    },
    methods: {
        initGeocoder() {
            if (this.geocoder) return

            this.geocoder = new MapboxGeocoder({
                accessToken: mapboxAccessToken,
                mapboxgl: Mapbox,
                placeholder: 'Search locations...',
                marker: false,
                countries: 'us',
            })

            // Add geocoder to the container
            this.geocoder.addTo(this.$refs.geocoderContainer)

            // When a result is selected, fly to that location
            this.geocoder.on('result', (e) => {
                const mapComponent = this.$refs.map
                if (mapComponent) {
                    mapComponent.flyTo({
                        center: e.result.center,
                        zoom: 12,
                    })
                }
            })

            // Clear the geocoder when no result is selected
            this.geocoder.on('clear', () => {
                const mapComponent = this.$refs.map
                if (mapComponent) {
                    mapComponent.fitBounds()
                }
            })

            // Style the geocoder input
            const geocoderInput = this.$refs.geocoderContainer.querySelector('.mapboxgl-ctrl-geocoder')
            if (geocoderInput) {
                geocoderInput.className += ' shadow-xl rounded-xl border'
            }
        },

        handleSearchInput: debounce(function () {
            if (this.$refs.map && this.searchQuery) {
                // Use Mapbox Geocoding API to search for locations
                this.$refs.map.searchLocation(this.searchQuery)
            }
        }, 300),

        async loadOpportunities() {
            try {
                // Load from cache first
                await this.loadFromCache()

                // Then fetch any new updates
                await this.fetchUpdates()

                // Clean old entries
                this.cleanCache()

                // Update markers from cache
                this.updateMarkersFromCache()
            } catch (error) {
                console.error('Error loading opportunities:', error)
            }
        },

        async loadFromCache() {
            try {
                const cached = localStorage.getItem(CACHE_KEY)
                if (cached) {
                    const { opportunities, lastChecked } = JSON.parse(cached)
                    this.opportunityCache = new Map(opportunities)
                    this.lastChecked = new Date(lastChecked)
                }
            } catch (error) {
                console.error('Error loading from cache:', error)
                // If cache is corrupted, clear it
                localStorage.removeItem(CACHE_KEY)
                this.opportunityCache = new Map()
            }
        },

        async fetchUpdates() {
            if (this.loading) return
            this.loading = true
            const threeMonthsAgo = new Date()
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3)

            let page = 0
            let hasMore = true
            const pageSize = 500
            const checkDate = this.lastChecked || threeMonthsAgo

            while (hasMore) {
                const query = {
                    search: {
                        // Uncomment this if we want reps to be able to get all branch ops
                        // branch: '6542bfb361c2b192db9dd9ae', //this.store?.user?.branches?.map((b) => b.id),
                        author: this.store?.user?.id,
                        'address.coordinates.lat': { $exists: true },
                        updated: {
                            $gte: checkDate,
                        },
                    },
                    sort: { updated: 1 },
                    fields: ['address.coordinates', 'status', 'updated', 'id', 'author', 'shotgun', 'ktwc', 'insuranceAccepted', 'contractSigned', 'estimate', 'discussion', 'inspection'],
                    limit: pageSize,
                    page: page,
                    depthLimit: 0,
                }

                const opportunities = await Mango.opportunities(query)

                // If we get fewer results than the page size, we've reached the end
                hasMore = opportunities.length === pageSize
                page++

                // Update cache with new opportunities
                opportunities.forEach((opp) => {
                    if (opp?.address?.coordinates?.lat) {
                        this.opportunityCache.set(opp.id, {
                            coordinates: [opp?.address?.coordinates?.lng, opp?.address?.coordinates?.lat],
                            status: opp.status,
                            updated: opp.updated,
                            id: opp.id,
                            author: opp.author,
                            shotgun: opp.shotgun,
                            ktwc: opp.ktwc,
                            insuranceAccepted: opp.insuranceAccepted,
                            contractSigned: opp.contractSigned,
                            estimate: opp.estimate,
                            discussion: opp.discussion,
                            inspection: opp.inspection,
                            lastCached: new Date().toISOString(),
                        })
                    }
                })

                this.lastChecked = new Date()
                this.saveToCache()
                this.updateMarkersFromCache()

                // If there are more pages, wait a short time to avoid overwhelming the server
                if (hasMore) {
                    await new Promise((resolve) => setTimeout(resolve, 100))
                }
            }

            this.loading = false
        },

        cleanCache() {
            const threeMonthsAgo = new Date(Date.now() - THREE_MONTHS)

            for (const [id, opp] of this.opportunityCache.entries()) {
                const updatedDate = new Date(opp.updated)
                if (updatedDate < threeMonthsAgo) {
                    this.opportunityCache.delete(id)
                }
            }

            this.saveToCache()
        },

        saveToCache() {
            const cacheData = {
                opportunities: Array.from(this.opportunityCache.entries()),
                lastChecked: this.lastChecked.toISOString(),
            }
            localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData))
        },

        updateMarkersFromCache() {
            this.markers = Array.from(this.opportunityCache.values())
        },

        handleMarkersLoaded() {
            console.log('Markers loaded successfully')
        },

        toggleStatus(status) {
            if (status === 'all') {
                // If 'all' is clicked
                this.selectedStatuses = ['all']
            } else {
                // Remove 'all' if it's present
                this.selectedStatuses = this.selectedStatuses.filter(s => s !== 'all')

                // Toggle the clicked status
                if (this.selectedStatuses.includes(status)) {
                    this.selectedStatuses = this.selectedStatuses.filter(s => s !== status)
                    // If no statuses are selected, default to 'all'
                    if (this.selectedStatuses.length === 0) {
                        this.selectedStatuses = ['all']
                    }
                } else {
                    this.selectedStatuses.push(status)
                }
            }
        },

        toggleKtwcFilter(filter) {
            const index = this.selectedKtwcFilters.indexOf(filter)
            if (index === -1) {
                this.selectedKtwcFilters.push(filter)
            } else {
                this.selectedKtwcFilters.splice(index, 1)
            }
        },
    },
    async activated() {
        console.log('map active!')
        if (this.lastChecked && (Date.now() - new Date(this.lastChecked)) > 5 * 60 * 1000) {
            await this.fetchUpdates()
            this.updateMarkersFromCache()
        }
    },
    mounted() {
        this.loadOpportunities()
        this.$nextTick(() => {
            this.initGeocoder()
        })
    },
    beforeUnmount() {
        if (this.geocoder) {
            try {
                this.geocoder.onRemove()
            } catch (error) {
                console.error('Error removing geocoder:', error)
            }
        }
    },
}
</script>

<style>
.opportunity-marker {
    cursor: pointer;
    transition: transform 0.2s;
}

.opportunity-marker:hover {
    transform: scale(1.2);
}

.mapboxgl-ctrl-geocoder {
    width: 100% !important;
    max-width: none !important;
    font-family: inherit !important;
    box-shadow: none !important;
}

.mapboxgl-ctrl-geocoder--input {
    height: 38px !important;
    padding: 0.5rem 1rem !important;
}

.mapboxgl-ctrl-geocoder--icon {
    top: 8px !important;
}

.mapboxgl-ctrl-geocoder--button {
    top: 3px !important;
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease-out;
    max-height: 200px;
}

.slide-enter-from,
.slide-leave-to {
    max-height: 0;
    opacity: 0;
    transform: translateY(-10px);
}

.slide-enter-to,
.slide-leave-from {
    max-height: 200px;
    opacity: 1;
    transform: translateY(0);
}
</style>
