<template>
    <div class="
        flex justify-center bg-white shadow-card dark:bg-black fixed bottom-0 left-0 w-full h-20 border-t dark:border-gray-700 z-50
    ">
        <div
            class="
                grid grid-cols-6 justify-between gap-4 w-full max-w-lg px-6 pb-4
            "
            :class="`grid-cols-${nav.length}`"
        >
            <button
                v-for="section in nav" :key="section.title"
                :class="[$route.path == section.path ? 'text-blue-500' : '']"
                class="flex flex-col items-center justify-center"
                @click="setTab(section)"
                :dataTest="section.title"
            >
                <div><i :class="`text-lg fal fa-${section.icon}`" /></div>
                <div class="text-2xs">{{ section.title }}</div>
            </button>
        </div>
    </div>
</template>

<script>
import login from './login.vue'
import InstallPrompt from '../partials/installPrompt.vue'
export default {
  components: { login, InstallPrompt },
    inject: ['store','darkMode'],
    data() {
        return {
            navHidden: false,
        }
    },
    methods: {
        setTab(section) {
            // This will make the "rep" tab take you back to your current opportunity.
            let updatingOpportunity = this.$route.path.includes('opportunities') && this.$route.params?.id
            if (updatingOpportunity && section.path != '/') this.store.currentOpportunityPath = this.$route.path

            this.$router.push(section.path)

            // This way if they hit "rep" a second time it will return them to the home screen
            if (section.path == this.store.currentOpportunityPath) this.store.currentOpportunityPath = null
        }
    },
    computed: {
        nav() {
            return [
                { title: 'Sales', path: this.store?.currentOpportunityPath || '/', icon: 'map-marker-alt' },
                { title: 'Ops', path: '/account/my-opportunities', icon: 'list' },
                { title: 'Shotgun', path: '/shotgun', icon: 'truck-pickup' },
                { title: 'Map', path: '/map', icon: 'map' },
                // { title: 'Chat', path: '/chat', icon: 'comments' },
                { title: 'Account', path: '/account', icon: 'user' },
                { title: 'FAQ', path: '/faq', icon: 'question' },
            ]
        }
    }
}
</script>
